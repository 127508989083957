import { atom } from 'recoil';

export interface Notification {
  _id: number;
  title: string;
  label: string;
  content: string;
  action: string;
  value: string;
  createdAt: string;
  readAt: string | null;
  isRead: boolean;
}

export const NotificationState = atom({
  key: 'NotificationState',
  default: {
    total: 0,
    notifications: [] as Notification[],
  },
});
