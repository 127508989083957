import { TeacherListItemInterface } from './../types/teacher-list-item.interface';
import { TeacherSortProps } from './../constants/teacher-sort';
import { PaginateData } from './../types/paginate-data.interface';
import { eduTipFieldMap } from './../constants/edu-tip-field';
import { privateAxiosInstance, publicAxiosInstance } from '.';
import { Search } from '../types/search.interface';
import { IEduTipListItem } from '../types/edu-tip-list-item.interface';
import { CounsellingListItemInterface } from '../types/counselling-list-item.interface';
import { FieldData } from '../routers/authorized/components/TeacherReservation';
import { PresentationListItemInterface } from '../types/presentation-list-item.interface';
import { LectureListItemInterface } from '../types/lecture-list-item.interface';

export interface KeywordResponse {
  _id: number;
  createdAt: string;
  text: string;
}

export interface EduTipListItem {
  _id: number;
  title: string;
  createdAt: string;
  content: string;
  isPrivate: boolean;
}

async function getKeyword() {
  const response = await privateAxiosInstance.get('/search');
  return response.data as Search[];
}

async function postKeyword(keyword: string) {
  await privateAxiosInstance.post(`/search/`, { keyword });
  return;
}

//EduTip
export type EduTipListFilterType =
  | keyof typeof eduTipFieldMap
  | 'etc'
  | 'total';

async function getEduTipList(
  page: number,
  search: string | {},
  major: EduTipListFilterType = 'total',
  minor: string[],
  isTotal?: boolean,
) {
  const isSearch = typeof search === 'string' ? `search=${search}&` : '';
  const total = isTotal ? `&isTotal=${isTotal}` : '';
  const minorProcessed =
    minor.length > 0
      ? '&' +
        minor
          .map((el, idx) => {
            return 'minor[' + idx + ']=' + el;
          })
          .join('&')
      : '';
  const response = await publicAxiosInstance.get(
    `/search/edutip?${isSearch}page=${page}&major=${major}${minorProcessed}${total}`,
  );
  return response.data as PaginateData<IEduTipListItem>;
}

async function getMinorList(major: EduTipListFilterType) {
  const url = `/search/minor?major=${major}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as string[];
}

async function getMinorField(major: EduTipListFilterType) {
  const url = `/search/minorfield?major=${major}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as FieldData[];
}

async function getCounsellingList(
  page: number,
  search: string | {},
  sort: string,
  major: EduTipListFilterType = 'total',
  minor: string[] = [],
  isTotal?: boolean,
) {
  const isSearch = typeof search === 'string' ? `&search=${search}` : '';
  const total = isTotal ? `&isTotal=${isTotal}` : '';
  const majorProcessed = typeof major === 'string' ? `&major=${major}` : '';
  const minorProcessed =
    minor.length > 0
      ? '&' +
        minor
          .map((el, idx) => {
            return 'minor[' + idx + ']=' + el;
          })
          .join('&')
      : '';
  const url = `/search/counselling?page=${page}&sort=${sort}${majorProcessed}${minorProcessed}${isSearch}${total}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<CounsellingListItemInterface>;
}

async function getPresentationList(
  page: number,
  search: string | {},
  sort: string,
  major: EduTipListFilterType = 'total',
  minor: string[] = [],
  isTotal?: boolean,
) {
  const isSearch = typeof search === 'string' ? `&search=${search}` : '';
  const total = isTotal ? `&isTotal=${isTotal}` : '';
  const majorProcessed = typeof major === 'string' ? `&major=${major}` : '';
  const minorProcessed =
    minor.length > 0
      ? '&' +
        minor
          .map((el, idx) => {
            return 'minor[' + idx + ']=' + el;
          })
          .join('&')
      : '';
  const url = `/search/presentation?page=${page}&sort=${sort}${majorProcessed}${minorProcessed}${isSearch}${total}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<PresentationListItemInterface>;
}

async function getLectureList(
  page: number,
  search: string | {},
  sort: string,
  major: EduTipListFilterType = 'total',
  minor: string[] = [],
  isTotal?: boolean,
) {
  const isSearch = typeof search === 'string' ? `&search=${search}` : '';
  const total = isTotal ? `&isTotal=${isTotal}` : '';
  const majorProcessed = typeof major === 'string' ? `&major=${major}` : '';
  const minorProcessed =
    minor.length > 0
      ? '&' +
        minor
          .map((el, idx) => {
            return 'minor[' + idx + ']=' + el;
          })
          .join('&')
      : '';
  const url = `/search/lecture?page=${page}&sort=${sort}${majorProcessed}${minorProcessed}${isSearch}${total}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<LectureListItemInterface>;
}

async function getTeacherList(
  page: number,
  search: string | {},
  sort: TeacherSortProps,
) {
  const isSearch = typeof search === 'string' ? `search=${search}&` : '';
  const isTotal = sort.isTotal ? `&isTotal=${sort.isTotal}` : '';
  const fieldProcessed =
    sort.field.length > 0
      ? '&' +
        sort.field
          .map((el, idx) => {
            return 'field[' + idx + ']=' + el;
          })
          .join('&')
      : '';
  const url = `/search/teacher?page=${page}&${isSearch}toggleChat=${sort.toggleChat}&togglePhone=${sort.togglePhone}&toggleScreen=${sort.toggleScreen}&etcText=${sort.etcText}${fieldProcessed}${isTotal}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<TeacherListItemInterface>;
}

export const searchApis = {
  getKeyword,
  postKeyword,
  getEduTipList,
  getMinorList,
  getMinorField,
  getCounsellingList,
  getPresentationList,
  getLectureList,
  getTeacherList,
};
