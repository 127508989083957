import axios from 'axios';

const isDev = process.env.NODE_ENV === 'development';

export const publicAxiosInstance = axios.create({
  withCredentials: false,
  baseURL: isDev ? '//localhost:5001/client-api' : '//askhow.co.kr/api',
});

export const privateAxiosInstance = axios.create({
  withCredentials: true,
  baseURL: isDev ? '//localhost:5001/client-api' : '//askhow.co.kr/api',
});
