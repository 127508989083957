import { FC, useMemo } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { UserRoleEnum } from '../constants/user-role';
import { AuthState } from '../contexts/AuthContext';
import { useAuth } from '../hooks/useAuth';

interface RoleBasedRouterProps {
  role: UserRoleEnum[];
}

const RoleBasedRouter: FC<RoleBasedRouterProps> = ({ role }) => {
  const { authState, isLogin } = useAuth();
  const location = useLocation();

  const to = useMemo(() => {
    if (role.includes(UserRoleEnum.NONE)) {
      return '/';
    }
    if (isLogin === true) {
      return '/unauthorized';
    } else {
      return '/login';
    }
  }, [isLogin, role]);

  return role.includes((authState as AuthState)?.role || UserRoleEnum.NONE) ? (
    <Outlet />
  ) : (
    <Navigate to={to} state={{ from: location }} replace />
  );
};

RoleBasedRouter.defaultProps = {} as Partial<RoleBasedRouterProps>;

export default RoleBasedRouter;
