export const qnaList = [
  { value: 'service', label: '서비스소개' },
  { value: 'purchaseAndUse', label: '상담상품 구매 및 이용' },
  { value: 'cancelRefundConfirm', label: '취소/환불/거래확정' },
  { value: 'rule', label: '규정 및 정책 안내' },
  { value: 'serviceRestriction', label: '서비스 이용제한' },
  { value: 'teacherRequest', label: '전문가 신청/관리/해지' },
  { value: 'counselling', label: '상담 서비스 등록/수정/노출' },
  { value: 'infoReview', label: '소식 알림/후기관리' },
  { value: 'payment', label: '정산' },
  { value: 'pc', label: 'PC 점검방법' },
  { value: 'bugReport', label: '오류제보' },
  { value: 'proposal', label: '제휴&제안' },
];

export const qnaCategoryOption: Record<string, string> = {
  service: '서비스소개',
  purchaseAndUse: '상담상품 구매 및 이용',
  cancelRefundConfirm: '취소/환불/거래확정',
  rule: '규정 및 정책 안내',
  serviceRestriction: '서비스 이용제한',
  teacherRequest: '전문가 신청/관리/해지',
  counselling: '상담 서비스 등록/수정/노출',
  infoReview: '소식 알림/후기관리',
  payment: '정산',
  pc: 'PC 점검방법',
  bugReport: '오류제보',
  proposal: '제휴&제안',
};
