import { FC, useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Notification } from '../atoms/NotificationState';
import { notificationApis } from '../axios/notification.apis';
import { useNotifications } from '../hooks/useNotifications';
import { arrowRightCircle, arrowRightCircleInactive } from '../images';

interface NotificationListItemProps extends Notification {
  menuRef?: any;
}

const NotificationListItem: FC<NotificationListItemProps> = (notification) => {
  const isProcess = useRef(false);
  const [, setNotifications] = useNotifications();
  const handleRead = useCallback(() => {
    if (notification.menuRef) {
      notification.menuRef?.current?.closeMenu &&
        notification.menuRef?.current?.closeMenu();
    }

    if (isProcess.current === true || notification.isRead === true) return;
    isProcess.current = true;

    notificationApis
      .readNotification(notification._id)
      .then((res) => {
        const { unReadCount } = res;
        setNotifications((prev) => {
          const next = prev.notifications.concat();
          const index = next.findIndex((v) => v._id === notification._id);
          if (index > -1) {
            const _data = {
              ...next[index],
              isRead: true,
            };

            next[index] = _data;
          }

          const result = {
            total: unReadCount,
            notifications: next,
          };
          return result;
        });
      })
      .catch(() => {})
      .finally(() => {
        isProcess.current = false;
      });
  }, [
    notification._id,
    notification.isRead,
    notification.menuRef,
    setNotifications,
  ]);
  const activeInfo = useMemo(() => {
    if (notification.isRead) {
      return {
        bg: 'bg-askhow-black-04',
        icon: arrowRightCircleInactive,
      };
    }
    return {
      bg: 'bg-askhow-primary-500',
      icon: arrowRightCircle,
    };
  }, [notification.isRead]);

  return (
    <Link
      to={notification.value || ''}
      key={notification._id}
      className="block py-6 border-b border-b-askhow-black-05"
      onClick={handleRead}
    >
      <div className="flex justify-between mb-[10px] items-center">
        <p
          className={`py-[6px] px-2 text-askhow-white rounded-4 text-body-3 leading-3 ${activeInfo.bg}`}
        >
          {notification.label}
        </p>
        <img className="w-5" alt="바로가기" src={activeInfo.icon} />
      </div>
      <div>
        <p className="font-bold text-body-1 line-clamp-1">
          {notification.title}
        </p>
        <p className="text-askhow-black-03 line-clamp-2 text-body-2">
          {notification.content}
        </p>
      </div>
    </Link>
  );
};

NotificationListItem.defaultProps = {} as Partial<NotificationListItemProps>;

export default NotificationListItem;
