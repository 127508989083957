import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UserRoleEnum } from '../constants/user-role';
import { AuthState } from '../contexts/AuthContext';
import { useAuth } from '../hooks/useAuth';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthDispatch } from '../hooks/useAuthDispatch';
import { useSideMenuState } from '../hooks/useSideMenuState';
import { useNotifications } from '../hooks/useNotifications';
import { Menu, MenuInstance } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useRecoilState } from 'recoil';
import { TopBannerState } from '../atoms/TopBannerState';
import HeaderTopBanner from '../Modals/HeaderTopBanner';
import NotificationListItem from '../components/NotificationListItem';
import Nodata from '../components/Nodata';
import { useModals } from '../hooks/useModal';
import SearchModal from '../Modals/SearchModal';
import _ from 'lodash';
import {
  notificationIcon,
  searchIconWhite,
  whiteHamburger,
  whiteLogo,
} from '../images';

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const { authState, isLogin } = useAuth();
  const [notifications] = useNotifications();
  const [, setIsOpen] = useSideMenuState();
  const { logout } = useAuthDispatch();
  const role = useMemo(() => {
    return (authState as AuthState)?.role || UserRoleEnum.NONE;
  }, [authState]);

  const [topBanner] = useRecoilState(TopBannerState);
  const headerHeight = useMemo(() => {
    if (topBanner !== null) {
      return 'h-24 lg:h-32';
    }
    return 'h-12 lg:h-16';
  }, [topBanner]);
  const notificationRef = useRef<MenuInstance>(null);

  const navigate = useNavigate();
  const { openModal } = useModals();
  const openSearchModal = useCallback(() => {
    openModal(SearchModal, {});
  }, [openModal]);

  const location = useLocation();
  const [showTopBanner, setShowTopBanner] = useState(false);

  const colors = useMemo(() => {
    if (showTopBanner) {
      return 'bg-gradient-to-b from-[rgba(0,134,255,1)] to-[rgba(24,145,255,1)]';
    } else {
      return 'bg-askhow-primary-500';
    }
  }, [showTopBanner]);

  useEffect(() => {
    if (location.pathname === '/') {
      const handler = _.throttle(() => {
        if (window.scrollY === 0) {
          setShowTopBanner(true);
        } else {
          setShowTopBanner(false);
        }
      }, 300);
      handler();
      window.addEventListener('scroll', handler);
      return () => {
        window.removeEventListener('scroll', handler);
      };
    }
  }, [location.pathname]);

  return (
    <header className={`mx-auto sticky top-0 z-10 ${headerHeight} ${colors}`}>
      <HeaderTopBanner value={topBanner} />
      <div className="container mx-auto flex justify-between items-center h-12 lg:h-16">
        <div className="flex items-center space-x-3">
          <button className="block lg:hidden" onClick={() => setIsOpen(true)}>
            <img src={whiteHamburger} alt="메뉴토글" className="w-6 h-6" />
          </button>
          <Link to="/">
            <img className="h-5 lg:h-6 " src={whiteLogo} alt="askhow" />
          </Link>
        </div>
        <nav className="right space-x-3 lg:space-x-7 flex items-center">
          <li className="inline-block lg:hidden w-6 h-6">
            <button type="button" onClick={openSearchModal}>
              <img src={searchIconWhite} alt="검색" className="w-6 h-6" />
            </button>
          </li>
          <li className="hidden lg:inline-block">
            <div className="cursor-pointer" onClick={openSearchModal}>
              <form className="h-8 bg-askhow-white bg-opacity-30 rounded-2xl py-1 px-3 flex items-center text-body-3  w-52">
                <div className="bg-transparent text-askhow-white h-6 flex-1 font-medium leading-6">
                  검색어를 입력하세요.
                </div>
                <div>
                  <img
                    src={searchIconWhite}
                    alt="검색하기"
                    className="w-5 h-5"
                  />
                </div>
              </form>
            </div>
          </li>
          {role !== UserRoleEnum.TEACHER && (
            <li className="hidden lg:inline-block">
              <Link
                className="text-askhow-white text-body-2 "
                to="/customer-center"
              >
                이용안내
              </Link>
            </li>
          )}
          {isLogin === false ? (
            <li className="hidden lg:inline-block">
              <Link
                className="inline-block text-askhow-primary-500 text-body-2 leading-8 bg-askhow-white px-4 h-8 rounded-2xl font-medium"
                to="login"
              >
                로그인/가입
              </Link>
            </li>
          ) : (
            <Fragment>
              <li className="hidden lg:inline-block">
                <Link
                  className="text-askhow-white text-body-2"
                  to="/mypage/account"
                >
                  마이페이지
                </Link>
              </li>
              <li className="hidden lg:inline-block">
                <button
                  className="text-askhow-white text-body-2 "
                  onClick={() => {
                    navigate('/login');
                    logout();
                  }}
                >
                  로그아웃
                </button>
              </li>
              <li className="inline-block">
                <Menu
                  menuButton={
                    <button className="relative block">
                      <img
                        src={notificationIcon}
                        alt="알림"
                        className="w-6 lg:w-7"
                      />
                      <span className="block absolute -top-1 -right-1 lg:top-0 lg:right-0 w-4 bg-askhow-white rounded-full text-askhow-primary-500 text-[10px] leading-4 text-center font-bold">
                        {notifications.total}
                      </span>
                    </button>
                  }
                  transition
                  direction="bottom"
                  align="end"
                  position="auto"
                  menuStyle={{
                    width: 396,
                    borderRadius: 10,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  menuClassName="max-w-[280px] lg:max-w-[396px]"
                  instanceRef={notificationRef}
                >
                  <div className="py-6 lg:py-10 px-4 lg:px-12 top-0 left-0 right-0 bottom-0">
                    <div className="flex justify-between pb-4 border-b border-b-askhow-black-05 items-center">
                      <p className="font-bold text-h3">알림</p>
                      <Link
                        to="/mypage/notification"
                        className="bg-askhow-black-02 py-[6px] px-3 rounded-4 text-center text-askhow-white font-medium text-body-3"
                        onClick={() => {
                          notificationRef.current?.closeMenu &&
                            notificationRef.current.closeMenu();
                        }}
                      >
                        전체보기
                      </Link>
                    </div>
                    <NotificationList className="pr-2 max-h-60 lg:max-h-[644px]">
                      {notifications.notifications.length === 0 ? (
                        <Nodata>알림이 없습니다.</Nodata>
                      ) : (
                        notifications.notifications.map((notification) => {
                          return (
                            <NotificationListItem
                              key={notification._id}
                              menuRef={notificationRef}
                              {...notification}
                            />
                          );
                        })
                      )}
                    </NotificationList>
                  </div>
                </Menu>
              </li>
            </Fragment>
          )}
          {isLogin === true && (
            <li className="hidden lg:inline-block">
              <button onClick={() => setIsOpen(true)}>
                <img src={whiteHamburger} alt="메뉴토글" className="w-7 h-7" />
              </button>
            </li>
          )}
        </nav>
      </div>
    </header>
  );
};

Header.defaultProps = {} as Partial<HeaderProps>;

export default Header;

const NotificationList = styled.div`
  overflow-y: overlay;

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #d9dde8;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;
