import { FormikProvider, useFormik } from 'formik';
import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { customCenterApis } from '../../axios/custom-center.apis';
import BlockButton from '../../components/BlockButton';
import CommonDropDown from '../../components/CommonDropDown';
import CommonToggle from '../../components/CommonToggle';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import HeaderWithBackButton from '../../components/HeaderWithBackButton';
import WhiteBoard from '../../components/WhiteBoard';
import { ModalText } from '../../constants/modal-text';
import { qnaCategoryOption, qnaList } from '../../constants/qnaList';
import { useLayoutHide } from '../../hooks/useLayoutHide';
import { useModals } from '../../hooks/useModal';
import AlertModal from '../../Modals/AlertModal';
import { qnaPostYup } from '../../Yup/qna.yup';
import FullLoading from '../../components/FullLoading';
import axios from 'axios';

interface QnaEditProps {}

interface Values {
  title: string;
  content: string;
  qnaField: string;
  isPrivate: boolean;
}
interface CoreProps {
  initData: {
    _id: number;
    title: string;
    qnaField: string;
    content: string;
    isPrivate: boolean;
  };
}

const Core: FC<CoreProps> = ({ initData }) => {
  const navigate = useNavigate();
  const { openModal } = useModals();

  const formik = useFormik<Values>({
    initialValues: {
      title: initData.title,
      content: initData.content,
      qnaField: initData.qnaField,
      isPrivate: initData.isPrivate,
    },
    onSubmit: async (value, helper) => {
      try {
        await customCenterApis.updateQna(initData._id, value);
        openModal(AlertModal, {
          content: ModalText.UPLOAD_DONE_TEXT,
          buttons: [
            {
              text: '확인',
              onClose: () => {
                navigate(`/customer-index/qna/${initData._id}`);
              },
            },
          ],
        });
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          const message =
            e?.response?.data?.message ?? '알 수 없는 오류가 발생하였습니다.';
          openModal(AlertModal, {
            content: message,
            buttons: [
              {
                text: '확인',
                onClose: () => navigate(`/customer-index/qna/${initData._id}`),
              },
            ],
          });
        } else {
          openModal(AlertModal, {
            content: '네트워크 오류가 발생하였습니다.',
          });
        }
        helper.setSubmitting(false);
      }
    },
    validationSchema: qnaPostYup,
  });

  function removeTag(html: string) {
    return html.replace(/(<([^>]+)>)/gi, '');
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <WhiteBoard className="my-10 lg:w-[870px] lg:my-20">
          <div className="text-askhow-black-01 font-medium text-body-1 mb-0.5">
            제목
          </div>
          <div className="text-center">
            <input
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="제목을 입력해주세요"
              className="pl-4 w-[100%] h-[48px] border rounded-4 border-askhow-black-04 placholder:text-askhow-black-05"
            />
          </div>
          <CustomErrorMessage>{formik.errors.title}</CustomErrorMessage>
          <div className="mt-6 text-askhow-black-01 font-medium text-body-1 mb-0.5">
            분야
          </div>
          <div>
            <CommonDropDown
              placeholder="분야를 선택해주세요"
              className="rounded-4 placholder:text-askhow-black-05 placeholder:text-body-1"
              borderColer="#B2B2C8"
              isSearchable={false}
              options={qnaList}
              value={
                formik.values.qnaField === null
                  ? null
                  : {
                      label: qnaCategoryOption[formik.values.qnaField] || '',
                      value: formik.values.qnaField,
                    }
              }
              onChange={(item) => {
                if (item) {
                  // @ts-ignore
                  formik.setFieldValue('qnaField', item.value);
                }
              }}
            />
          </div>
          <CustomErrorMessage>{formik.errors.qnaField}</CustomErrorMessage>
          <div className="text-askhow-black-01 font-medium text-body-1 pt-10 mb-0.5">
            내용
          </div>
          <div className="text-center">
            <textarea
              name="content"
              value={removeTag(formik.values.content)}
              onChange={formik.handleChange}
              placeholder="내용을 입력해주세요"
              className="px-4 py-3 w-[100%] h-36 border rounded-4 border-askhow-black-04 placholder:text-askhow-black-05"
              maxLength={1000}
            />
          </div>

          <CustomErrorMessage>{formik.errors.content}</CustomErrorMessage>
          <div className="text-askhow-black-01 font-medium text-body-1 pt-7 pr-3 mb-0.5 inline-block align-bottom">
            비밀글
          </div>
          <div className="inline-block">
            <CommonToggle
              name="isPrivate"
              checked={formik.values.isPrivate}
              onChange={formik.handleChange}
            />
          </div>
          <div className="pt-8 lg:mb-10">
            <BlockButton color="black" type="submit">
              수정하기
            </BlockButton>
          </div>
        </WhiteBoard>
      </form>
    </FormikProvider>
  );
};

const QnaEdit: FC<QnaEditProps> = () => {
  const navigate = useNavigate();
  const { openModal } = useModals();
  useLayoutHide();
  const [initialData, setinitialData] = useState<{
    _id: number;
    title: string;
    qnaField: string;
    content: string;
    isPrivate: boolean;
  } | null>(null);
  const param = useParams<'id'>();

  useEffect(() => {
    if (param.id) {
      let mounted = true;
      customCenterApis
        .getQnaOneForEdit(+param.id)
        .then((data) => {
          if (mounted === true) {
            setinitialData(data);
          }
        })
        .catch((e) => {
          if (axios.isAxiosError(e)) {
            const message =
              e?.response?.data?.message ?? '알 수 없는 오류가 발생하였습니다.';
            openModal(AlertModal, {
              content: message,
              buttons: [
                {
                  text: '확인',
                  onClose: () => navigate(`/customer-index/qna/${param.id}`),
                },
              ],
            });
          } else {
            openModal(AlertModal, {
              content: '네트워크 오류가 발생하였습니다.',
            });
          }
        });

      return () => {
        mounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id]);

  return (
    <div>
      <div>
        <HeaderWithBackButton label="문의하기" />
        <div className="bg-askhow-black-08 h-[calc(100vh-48px)] lg:h-[calc(100vh-64px)] overflow-auto">
          <div className="container mx-auto">
            {initialData === null ? (
              <FullLoading />
            ) : (
              <Core initData={initialData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

QnaEdit.defaultProps = {} as Partial<QnaEditProps>;

export default QnaEdit;
