import { FC, useCallback, useMemo } from 'react';
import { TopBanner } from '../atoms/TopBannerState';
import { UserRoleEnum } from '../constants/user-role';
import { useAuth } from '../hooks/useAuth';
import { useModals } from '../hooks/useModal';
import ReserveNotificationModal from './ReserveNotificationModal';

interface HeaderTopBannerProps {
  value: TopBanner;
}

const TypeMap: Record<string, string> = {
  chat: '채팅상담',
  phone: '전화상담',
  video: '화상상담',
};

const HeaderTopBanner: FC<HeaderTopBannerProps> = ({ value }) => {
  const { isLogin, authState } = useAuth();

  const { openModal } = useModals();
  const handleJoin = useCallback(() => {
    if (value?.type === 'phone') {
      openModal(ReserveNotificationModal, { value: value });
    } else if (value?.type === 'chat') {
      // navigate('/chatroom', { state: { id: value.payload } });
      window.open('/chatroom?id=' + value.payload);
    } else if (value?.type === 'video') {
      const tab = window.open(value.payload);
      tab?.focus && tab.focus();
    } else if (value?.type === 'presentation') {
      const tab = window.open(value.payload);
      tab?.focus && tab.focus();
    } else if (value?.type === 'lecture') {
      const tab = window.open(value.payload);
      tab?.focus && tab.focus();
    }
  }, [openModal, value]);

  if (isLogin === false) {
    return null;
  }
  if (value === null) {
    return null;
  }

  return (
    <div className="h-12 lg:h-16 bg-white">
      <div className="container mx-auto h-full flex justify-between items-center">
        <p className="font-medium text-body-2 ">
          {value.other ? (
            <>
              <span className="font-bold">{value.other.name}</span>
              {value.other.type === 'TEACHER' ? '전문가님과의 ' : '님과의 '}
            </>
          ) : null}
          <span className="font-bold">
            {TypeMap[value.type] ?? value.title}
          </span>{' '}
          시간입니다.
        </p>
        {!(
          authState.role !== UserRoleEnum.TEACHER && value.type === 'phone'
        ) && (
          <button
            className="bg-askhow-primary-500 rounded-5 py-[6px] px-6 text-askhow-white text-body-2 "
            type="button"
            onClick={handleJoin}
          >
            입장하기
          </button>
        )}
      </div>
    </div>
  );
};

HeaderTopBanner.defaultProps = {} as Partial<HeaderTopBannerProps>;

export default HeaderTopBanner;
