import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Modals from './Modals/Modals';
import AuthProvider from './contexts/AuthProvider';
import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './utils/queryclient';

ReactModal.setAppElement('#root');

const container = document.getElementById('root');
const root = createRoot(container!);

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <AuthProvider>
          <Router>
            <App />
            <Modals />
          </Router>
        </AuthProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
