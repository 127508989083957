import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import { ConsultDoneInfo } from '../atoms/RequireReviewState';
import { consultApis } from '../axios/consult.apis';
import BlockButton from '../components/BlockButton';
import Rating from '../components/Rating';
import { ModalText } from '../constants/modal-text';
import { UserRoleEnum } from '../constants/user-role';
import { useAuth } from '../hooks/useAuth';
import { useModals } from '../hooks/useModal';
import { chatConsultIcon, phoneConsultIcon, videoConsultIcon } from '../images';
import AlertModal from './AlertModal';
import CustomModal from './components/CustomModal';
import { presentationApis } from '../axios/presentation.apis';
import { lectureApis } from '../axios/lecture.apis';
import { setNeverNotSeen } from '../utils/never-seen';

interface ConsultDoneModalProps {
  data: ConsultDoneInfo;
  onSubmitAfter?: () => any;
}

function RenderConsultTeacher(props: {
  onClose: () => void;
  image: string | null;
  clientName: string;
  title: string;
}) {
  return (
    <CustomModal
      onRequestClose={props.onClose}
      className="py-5 lg:py-10 px-6 lg:px-12"
    >
      <div className="text-center mb-4">
        {props.image && (
          <img
            src={props.image}
            alt="ICON"
            className="w-30 h-30 inline-block"
          />
        )}
      </div>
      <h2 className="font-bold text-h3  text-center">종료안내</h2>
      <p className="text-body-2 text-center ">
        {props.clientName}님과의 {props.title}이
      </p>
      <p className="text-body-2 text-center mb-8">종료되었습니다.</p>
      <BlockButton type="button" onClick={props.onClose}>
        상담종료
      </BlockButton>
    </CustomModal>
  );
}

function RenderConsultUser(props: {
  onClose: () => void;
  onPermantClose: () => void;
  image: string | null;
  title: string;
}) {
  const formik = useFormikContext<{
    star: number;
    text: string;
  }>();
  return (
    <CustomModal
      onRequestClose={props.onClose}
      className="py-5 lg:py-10 px-6 lg:px-12"
    >
      <div className="text-center mb-3">
        {props.image && (
          <img
            src={props.image}
            alt="ICON"
            className="w-30 h-30 inline-block"
          />
        )}
      </div>
      <h2 className="font-bold text-h3  text-center mb-1">종료안내</h2>
      <p className="text-body-2  text-center">
        {props.title}은(는) 만족스러우셨나요?
      </p>
      <p className="text-body-2  text-center mb-8">
        리뷰를 적어 전문가님께 후기를 알려주세요:)
      </p>
      <form onSubmit={formik.handleSubmit}>
        <Rating
          value={formik.values.star}
          onChange={(next) => {
            formik.setFieldValue('star', next);
          }}
          className="flex justify-center"
        />
        <textarea
          name="text"
          className="font-medium text-body-2  border border-askhow-black-05 rounded-5 py-3 px-4 w-full h-[154px] resize-none mb-8 mt-4"
          placeholder="내용을 입력해주세요."
          value={formik.values.text}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          maxLength={200}
        />
        <BlockButton className="block mb-3" type="submit">
          리뷰등록
        </BlockButton>
        <BlockButton
          className="block bg-askhow-black-05"
          type="button"
          onClick={props.onPermantClose}
        >
          다시보지않기
        </BlockButton>
      </form>
    </CustomModal>
  );
}

const ImageMap: Record<
  'chat' | 'video' | 'phone' | 'presentation' | 'lecture',
  string
> = {
  chat: chatConsultIcon,
  phone: phoneConsultIcon,
  video: videoConsultIcon,
  lecture: chatConsultIcon,
  presentation: chatConsultIcon,
};

const ConsultDoneModal: FC<ConsultDoneModalProps> = ({
  data,
  onSubmitAfter,
}) => {
  const { isLogin, authState } = useAuth();
  const { openModal, closeModal } = useModals();

  const image = data?.type ? ImageMap[data.type] : null;

  const closeHandler = useCallback(() => {
    if (data) {
      setNeverNotSeen({
        id: data._id,
        isTeacher: data.isTeacher,
        next: true,
        type: data.type,
        keyOffset: 'done',
      });
      closeModal(ConsultDoneModal);
    }
  }, [closeModal, data]);

  const formik = useFormik({
    initialValues: {
      star: 5,
      text: '',
    },
    onSubmit: async (values, helper) => {
      if (!data) {
        return;
      }
      try {
        if (data.type === 'presentation') {
          await presentationApis.createReview(
            data._id,
            values.star,
            values.text
          );
        } else if (data.type === 'lecture') {
          if (!data.item_id) {
            return;
          }
          await lectureApis.createReview(
            data._id,
            data.item_id,
            values.star,
            values.text
          );
        } else {
          await consultApis.createReview(data._id, values.star, values.text);
        }
        openModal(AlertModal, { content: ModalText.UPLOAD_DONE_TEXT });
        onSubmitAfter && onSubmitAfter();
        closeModal(ConsultDoneModal);
      } catch (e: any) {
        const { message } = e?.response?.data;
        openModal(AlertModal, {
          content: message || ModalText.UPLOAD_ERROR_TEXT,
        });
      }
    },
  });

  useEffect(() => {
    if (isLogin === false) {
      closeModal(ConsultDoneModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  if (isLogin === false || data === null) {
    return null;
  }

  if (authState.role === UserRoleEnum.TEACHER) {
    return (
      <RenderConsultTeacher
        clientName={data.client.name}
        image={image}
        onClose={closeHandler}
        title={data.title}
      />
    );
  }

  return (
    <FormikProvider value={formik}>
      <RenderConsultUser
        image={image}
        onClose={() => {
          closeModal(ConsultDoneModal);
        }}
        onPermantClose={closeHandler}
        title={data.title}
      />
    </FormikProvider>
  );
};

ConsultDoneModal.defaultProps = {} as Partial<ConsultDoneModalProps>;

export default ConsultDoneModal;
