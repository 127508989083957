import { FC, useMemo } from 'react';
import { emptyStarIcon, fullStarIcon } from '../images';

interface RatingProps {
  value: number;
  readonly?: boolean;
  onChange?: (next: number) => void;
  useHalf?: boolean;
  className?: string;
}

interface StarProps {
  value: number;
  onFullClick?: () => void;
  onHalfClick?: () => void;
}

const Star: FC<StarProps> = ({ value, onFullClick, onHalfClick }) => {
  const starImage = useMemo(() => {
    if (value === 1) {
      return fullStarIcon;
    }
    return emptyStarIcon;
  }, [value]);
  const altText = useMemo(() => {
    if (value === 1) {
      return '꽉찬별';
    } else {
      return '텅빈별';
    }
  }, [value]);

  return (
    <div className="w-4 h-4 inline-block relative">
      <img src={starImage} className="w-4 h-4" alt={altText} />
      <button
        type="button"
        onClick={onHalfClick}
        className="absolute top-0 left-0 bottom-0 w-1/2"
      ></button>
      <button
        type="button"
        onClick={onFullClick}
        className="absolute top-0 right-0 bottom-0 w-1/2"
      ></button>
    </div>
  );
};

const Rating: FC<RatingProps> = ({
  value,
  onChange,
  readonly = false,
  useHalf = false,
  className = '',
}) => {
  const stars = useMemo(() => {
    const _stars = [0, 0, 0, 0, 0];
    return _stars.map((v, index) => {
      const full = index + 1;
      const half = index + 0.5;

      if (value >= full) {
        return 1.0;
      }
      if (useHalf === true && value >= half) {
        return 0.5;
      }
      return 0;
    });
  }, [useHalf, value]);

  return (
    <div className={className}>
      {stars.map((star, index) => {
        const handleFullClick = () => {
          const next = index + 1;
          onChange && onChange(next);
        };
        const handleHalfClick = () => {
          let next = index + 1;
          if (useHalf === true) {
            next = index + 0.5;
          }
          onChange && onChange(next);
        };
        return (
          <Star
            key={`${index}`}
            value={star}
            onFullClick={handleFullClick}
            onHalfClick={handleHalfClick}
          />
        );
      })}
    </div>
  );
};

Rating.defaultProps = {} as Partial<RatingProps>;

export default Rating;
