import { FC, useCallback, useMemo } from 'react';
import { TopBanner } from '../atoms/TopBannerState';
import BlockButton from '../components/BlockButton';
import { useModals } from '../hooks/useModal';
import { chatConsultIcon, phoneConsultIcon, videoConsultIcon } from '../images';
import CustomModal from './components/CustomModal';
import { setNeverNotSeen } from '../utils/never-seen';

interface ReserveNotificationModalProps {
  value: TopBanner;
}

const teacher_video_text = '{{}} 전문가님과의 화상상담';
const client_video_text = '{{}}님과의 화상상담';
const teacher_chat_text = '{{}} 전문가님과의 채팅상담';
const client_chat_text = '{{}}님과의 채팅상담을';
const teacher_phone_text = '{{}} 전문가님과의 전화상담';
const client_phone_text = '{{}}님과의 전화상담';

const messages = {
  teacher_video_text,
  client_video_text,
  teacher_chat_text,
  client_chat_text,
  teacher_phone_text,
  client_phone_text,
};

const TypeHeaderMap: Record<string, string> = {
  presentation: '설명회 시작',
  lecture: '강의 시작',
};

const ReserveNotificationModal: FC<ReserveNotificationModalProps> = ({
  value,
}) => {
  const message = useMemo(() => {
    if (value?.other) {
      const _type = value.isTeacher === true ? 'teacher' : 'client';

      const _msg = (messages as any)[`${_type}_${value.type}_text`];
      if (_msg) {
        return _msg.replace('{{}}', value.other.name);
      }
    }
    return value?.title ?? '';
  }, [value]);
  const { closeModal } = useModals();
  const close = useCallback(() => {
    closeModal(ReserveNotificationModal);
  }, [closeModal]);

  const handleNeverNotSeen = useCallback(() => {
    if (value) {
      setNeverNotSeen({
        id: value._id,
        isTeacher: value.isTeacher,
        next: true,
        type: value.type,
      });
      close();
    }
  }, [close, value]);
  const handleJoinChat = useCallback(() => {
    if (value?.type === 'chat') {
      window.open('/chatroom?id=' + value.payload);
    }
    if (value) {
      setNeverNotSeen({
        id: value._id,
        isTeacher: value.isTeacher,
        next: true,
        type: value.type,
      });
    }
    close();
  }, [close, value]);

  const handleJoinVideo = useCallback(() => {
    if (typeof value?.payload === 'string') {
      const tab = window.open(value.payload);
      tab?.focus && tab.focus();
    }
    if (value) {
      setNeverNotSeen({
        id: value._id,
        isTeacher: value.isTeacher,
        next: true,
        type: value.type,
      });
    }
    close();
  }, [close, value]);

  if (!value) {
    return null;
  }

  return (
    <CustomModal onRequestClose={close} className="px-6 lg:px-12 py-5 lg:py-10">
      <div className="text-center mb-3">
        <img
          src={
            value?.type === 'chat'
              ? chatConsultIcon
              : value?.type === 'phone'
              ? phoneConsultIcon
              : videoConsultIcon
          }
          alt=""
          className="inline-block w-30"
        />
      </div>
      <h2 className="text-center font-bold text-h3  mb-1">
        {TypeHeaderMap[value.type] ?? '상담시작'}
      </h2>
      <p className="text-body-2 text-center ">
        {message}
        {value?.isTeacher === true
          ? '을(를) 진행해주세요.'
          : '이(가) 시작되었습니다.'}
      </p>
      {value?.type === 'chat' ? (
        <div className="mt-8 space-y-3">
          <BlockButton className="block" onClick={handleJoinChat}>
            입장하기
          </BlockButton>
          <BlockButton
            className="block"
            color="gray"
            onClick={handleNeverNotSeen}
          >
            다시보지않기
          </BlockButton>
        </div>
      ) : value?.type === 'phone' ? (
        <div className="mt-8 space-y-3">
          <BlockButton className="block">{value.payload}</BlockButton>
          <BlockButton
            className="block"
            color="gray"
            onClick={handleNeverNotSeen}
          >
            다시보지않기
          </BlockButton>
        </div>
      ) : value?.type === 'video' ? (
        <div className="mt-8 space-y-3">
          <BlockButton className="block" onClick={handleJoinVideo}>
            입장하기
          </BlockButton>
          <BlockButton
            className="block"
            color="gray"
            onClick={handleNeverNotSeen}
          >
            다시보지않기
          </BlockButton>
        </div>
      ) : value.type === 'presentation' ? (
        <div className="mt-8 space-y-3">
          <BlockButton className="block" onClick={handleJoinVideo}>
            입장하기
          </BlockButton>
          <BlockButton
            className="block"
            color="gray"
            onClick={handleNeverNotSeen}
          >
            다시보지않기
          </BlockButton>
        </div>
      ) : value.type === 'lecture' ? (
        <div className="mt-8 space-y-3">
          <BlockButton
            className="block"
            type="button"
            onClick={handleJoinVideo}
          >
            입장하기
          </BlockButton>
          <BlockButton
            className="block"
            type="button"
            color="gray"
            onClick={handleNeverNotSeen}
          >
            다시보지않기
          </BlockButton>
        </div>
      ) : null}
    </CustomModal>
  );
};

ReserveNotificationModal.defaultProps =
  {} as Partial<ReserveNotificationModalProps>;

export default ReserveNotificationModal;
