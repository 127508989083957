import axios from 'axios';
import {
  FC,
  memo,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { privateAxiosInstance } from '../axios';
import { authApis } from '../axios/auth.apis';
import {
  AuthDispatchContext,
  AuthState,
  AuthStateContext,
  LoginParams,
} from './AuthContext';

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState<AuthState | {}>({});

  const login = async (params: LoginParams) => {
    const response = await authApis.login(
      params.username,
      params.password,
      params.isTeacher,
      params.isPersistLogin
    );

    setAuth(response);
  };

  const loginForce = async (params: AuthState) => {
    setAuth(params);
  };

  const logout = async () => {
    await authApis.logout();
    setAuth({});
  };
  const loadUser = async () => {
    const data = await authApis.loadMe();
    setAuth((prev: AuthState) => ({
      access_token: prev.access_token,
      ...data,
    }));
  };

  const dispatch = useMemo(() => {
    return {
      login,
      logout,
      loadUser,
      loginForce,
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    const autoLogin = async () => {
      if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem('persist-login');
        const isPersistLogin = item ? JSON.parse(item) : false;

        try {
          const { access_token } = await authApis.getAccessToken(
            isPersistLogin
          );

          const data = await authApis.loadMe(access_token);

          if (mounted === true) {
            setAuth({
              ...data,
              access_token,
            });
          }
        } catch {}
      }
    };
    autoLogin().finally(() => {
      if (mounted === true) {
        setIsLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  const authRef = useRef(auth as AuthState);
  useLayoutEffect(() => {
    authRef.current = auth as AuthState;
  }, [auth]);

  useEffect(() => {
    const requestInterceptor = privateAxiosInstance.interceptors.request.use(
      (config) => {
        if (
          authRef.current.access_token &&
          !((config.headers as any) || {}).Authorization
        ) {
          (
            (config.headers as any) || {}
          ).Authorization = `Bearer ${authRef.current.access_token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = privateAxiosInstance.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        const regexp = /^\/auth/gi;
        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          !regexp.test(err?.response?.config?.url || '')
        ) {
          originalConfig._retry = true;
          try {
            try {
              let is_persist = false;
              if (typeof window !== 'undefined') {
                const item = window.localStorage.getItem('persist-login');
                is_persist = item ? JSON.parse(item) : false;
              }
              const { access_token } = await authApis.getAccessToken(
                is_persist
              );
              setAuth((prev) => ({
                ...prev,
                access_token,
              }));
              originalConfig.headers.Authorization = `Bearer ${access_token}`;
            } catch {
              //NOTHING
            }
            return axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        return Promise.reject(err);
      }
    );
    return () => {
      privateAxiosInstance.interceptors.request.eject(requestInterceptor);
      privateAxiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  if (isLoading === true) {
    return null;
  }

  return (
    <AuthStateContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

AuthProvider.defaultProps = {} as Partial<AuthProviderProps>;

export default memo(AuthProvider);
