import { privateAxiosInstance, publicAxiosInstance } from '.';
import { SelectOption } from '../constants/children-select-option';
import { UserRoleEnum } from '../constants/user-role';
import { queryClient } from '../utils/queryclient';

interface RegisterParam {
  email: string;
  password: string;
  passwordConfirm: string;
  imp_uid: string | null;
  address?: string;
  marketting?: boolean;
  parent_imp_uid?: string | null;
  children?: { majorCategory: SelectOption; grade: SelectOption }[];
  type: UserRoleEnum;
  address_detail?: string;
  imp_pwd: string | null;
}

async function register(value: RegisterParam) {
  const body: any = {
    email: value.email,
    password: value.password,
    imp_uid: value.imp_uid,
    address: value.address,
    address_detail: value.address_detail,
    marketting: value.marketting,
    role: value.type,
  };
  if (value.type === UserRoleEnum.UNDER) {
    body.parent_imp_uid = value.parent_imp_uid;
  } else if (value.type === UserRoleEnum.UPPER) {
    body.children = (value.children || []).map((v) => ({
      majorCategory: v.majorCategory.value,
      grade: v.grade?.value || '',
    }));
  }

  const response = await publicAxiosInstance.post('/auth/register', body);
  return response.data;
}

async function login(
  username: string,
  password: string,
  isTeacher: boolean,
  isPersist: boolean
) {
  const response = await privateAxiosInstance.post('/auth/login', {
    username,
    password,
    isTeacher,
    persistLogin: isPersist,
  });
  return response.data;
}

async function logout() {
  try {
    await privateAxiosInstance.get('/auth/logout');
    queryClient.clear();
  } catch {
    //NOTHING
  }
  return 'good';
}

async function getAccessToken(is_persist: boolean) {
  const url = `/auth/access-token?is_persist=${is_persist}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as { access_token: string };
}

async function loadMe(access_token?: string) {
  const url = '/auth/me';
  const opts = access_token
    ? {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    : {};
  const response = await privateAxiosInstance.get(url, opts);
  return response.data as {
    _id: number;
    email: string;
    role: UserRoleEnum;
  };
}

async function loginSocial(
  id: string,
  email: string,
  type: string,
  isTeacher: boolean,
  isPersist: boolean
) {
  const response = await privateAxiosInstance.post('/auth/login/social', {
    id,
    email,
    type,
    isTeacher,
    persistLogin: isPersist,
  });
  return response.data as {
    method: 'register' | 'login' | 'error';
    data: any;
  };
}

interface RegisterSocialParam {
  email: string;
  type: 'naver' | 'kakao' | 'facebook' | 'google';
  id: string;
  imp_uid: string | null;
  address?: string;
  marketting?: boolean;
  parent_imp_uid?: string | null;
  children?: { majorCategory: SelectOption; grade: SelectOption }[];
  role: UserRoleEnum;
  address_detail?: string;
}
async function registerSocial(value: RegisterSocialParam) {
  const body: any = {
    id: value.id,
    email: value.email,
    type: value.type,
    imp_uid: value.imp_uid,
    address: value.address,
    marketting: value.marketting,
    role: value.role,
    address_detail: value.address_detail,
  };
  if (value.role === UserRoleEnum.UNDER) {
    body.parent_imp_uid = value.parent_imp_uid;
  } else if (value.role === UserRoleEnum.UPPER) {
    body.children = (value.children || []).map((v) => ({
      majorCategory: v.majorCategory.value,
      grade: v.grade?.value || '',
    }));
  }

  const response = await publicAxiosInstance.post(
    '/auth/register-social',
    body
  );

  return response.data;
}

async function findAccount(imp_uid: string, isTeacher: boolean) {
  const response = await publicAxiosInstance.post('/auth/find-account', {
    imp_uid,
    isTeacher,
  });
  return response.data as {
    type: 'naver' | 'google' | 'kakao' | 'facebook' | 'email';
    email: string;
  };
}

async function findPw(email: string, imp_uid: string, isTeacher: boolean) {
  const response = await publicAxiosInstance.post('/auth/find-pw', {
    email,
    imp_uid,
    isTeacher,
  });
  return response.data as {
    _id: number;
    email: string;
    access_token: string;
  };
}
async function findPwReset(id: number, password: string, accessToken: string) {
  const response = await publicAxiosInstance.post('/auth/find-pw/reset', {
    id,
    password,
    access_token: accessToken,
  });
  return response.data;
}

async function checkNotInput(id: string) {
  try {
    const response = await privateAxiosInstance.post('/auth/check-not-input', {
      id,
    });
    console.log(response.data as boolean);
    return response.data as boolean;
  } catch (e) {
    return false;
  }
}

export const authApis = {
  register,
  registerSocial,
  login,
  loginSocial,
  logout,
  getAccessToken,
  loadMe,
  findAccount,
  findPw,
  findPwReset,
  checkNotInput,
};
