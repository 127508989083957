import { createContext } from 'react';
import { UserRoleEnum } from '../constants/user-role';

export type LoginParams = {
  username: string;
  password: string;
  isTeacher: boolean;
  isPersistLogin: boolean;
};
type SocialType = 'kakao' | 'naver' | 'facebook' | 'google';

export type SocialLoginParams = {
  socialType: 'kakao' | 'naver' | 'facebook' | 'google';
  id: string;
  role: UserRoleEnum;
  email: string;
  isTeacher: boolean;
  isPersistLogin: boolean;
};

export interface AuthState {
  _id: number;
  role: UserRoleEnum;
  email: string;
  name: string;
  profile_image: { path: string } | null;
  access_token: string;
  registerFrom: 'email' & SocialType;
  agreeMarketing: boolean;
}

export interface AuthDispatch {
  login: (params: LoginParams) => Promise<void>;
  loginForce: (params: AuthState) => Promise<void>;
  logout: () => Promise<void>;
  loadUser: () => Promise<void>;
}

export const AuthDispatchContext = createContext<AuthDispatch>({
  loadUser: async () => {},
  login: async () => {},
  logout: async () => {},
  loginForce: async () => {},
});

export const AuthStateContext = createContext<AuthState | {}>({});
