import { qnaFieldMap } from './../constants/qna-field';
import { publicAxiosInstance, privateAxiosInstance } from '.';
import { CustomCenter } from '../types/custom-center.interface';
import { PaginateData } from '../types/paginate-data.interface';

export interface NoticeListItem {
  _id: number;
  title: string;
  createdAt: string;
  content: string;
}

export interface NoticeDetailResponse {
  _id: number;
  createdAt: string;
  file?: any;
  title: string;
  content: string;
}

export interface QnaListItem {
  _id: number;
  title: string;
  createdAt: string;
  content: string;
  isPrivate: boolean;
}

export interface QnaDetailResponse {
  _id: number;
  createdAt: string;
  file?: any;
  title: string;
  content: string;
  answer: string;
  answeredAt: string;
  isPrivate: boolean;
  writerId: number;
}

async function getCustomCenterData() {
  const response = await publicAxiosInstance.get('/custom-center');
  return response.data as CustomCenter;
}

async function getNoticeList(search: string, page: number) {
  const url = `/customer-index/notice?search=${search}&page=${page}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<NoticeListItem>;
}

async function getNoticeDetail(noticeId: number) {
  const url = `/customer-index/notice/${noticeId}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as NoticeDetailResponse;
}

async function getFaqList(page: number, search: string) {
  const url = `/customer-index/faq?page=${page}&search=${search}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<NoticeListItem>;
}

async function getFaqDetail(faqId: number) {
  const url = `/customer-index/faq/${faqId}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as NoticeDetailResponse;
}

export type QnaListFilterType = keyof typeof qnaFieldMap | 'total';
async function getQnaList(
  page: number,
  filter: QnaListFilterType,
  search: string
) {
  const url = `/customer-index/qna?page=${page}&filter=${filter}&search=${search}`;
  const response = await publicAxiosInstance.get(url);
  return response.data as PaginateData<QnaListItem>;
}

async function deleteQna(qnaId: number) {
  const url = `/customer-index/qna/${qnaId}`;
  await privateAxiosInstance.delete(url);
}

async function getQnaDetail(qnaId: number, accountId?: number) {
  let url = `/customer-index/qna/${qnaId}`;
  if (accountId) {
    url = `/customer-index/qna/${qnaId}?accountId=${accountId}`;
  }
  const response = await privateAxiosInstance.get(url);
  return response.data as QnaDetailResponse;
}

async function postQna(data: {
  title: string;
  content: string;
  qnaField: string | null;
  isPrivate: boolean;
}) {
  const response = await privateAxiosInstance.post(
    '/customer-index/qna/create',
    data
  );
  return response.data;
}

async function getQnaOneForEdit(id: number) {
  const response = await privateAxiosInstance.get(
    `/customer-index/qna/${id}/edit`
  );
  return response.data as {
    _id: number;
    title: string;
    qnaField: string;
    content: string;
    isPrivate: boolean;
  };
}

async function updateQna(
  id: number,
  data: {
    title: string;
    content: string;
    qnaField: string | null;
    isPrivate: boolean;
  }
) {
  const response = await privateAxiosInstance.patch(
    `/customer-index/qna/${id}`,
    data
  );
  return response.data;
}

export const customCenterApis = {
  getCustomCenterData,
  getNoticeList,
  getNoticeDetail,
  getFaqList,
  getFaqDetail,
  getQnaList,
  getQnaDetail,
  postQna,
  deleteQna,
  getQnaOneForEdit,
  updateQna,
};
