import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { TopBannerState } from '../atoms/TopBannerState';
import { reserveApis } from '../axios/reserve.apis';
import { useAuth } from './useAuth';

export function useFetchCommingSoonReserve() {
  const { isLogin } = useAuth();
  const location = useLocation();
  const [, setTopBanner] = useRecoilState(TopBannerState);

  useEffect(() => {
    if (isLogin === false) {
      setTopBanner(null);
      return;
    }
    let mounted = true;

    reserveApis
      .getCommingSoon()
      .then((res) => {
        if (mounted === true) {
          setTopBanner(res.result);
        }
      })
      .catch(() => {
        setTopBanner(null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, location.pathname]);
}
