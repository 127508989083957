import { useEffect } from 'react';
import ConsultDoneModal from '../Modals/ConsultDoneModal';
import { useAuth } from './useAuth';
import { useConsultDoneInfo } from './useConsultDoneInfo';
import { useModals } from './useModal';
import { getNeverNotSeen } from '../utils/never-seen';

export function useConsultDoneModal() {
  const { isLogin } = useAuth();
  const [data] = useConsultDoneInfo();

  const { closeModal, includeModal, openModal } = useModals();

  useEffect(() => {
    if (data) {
      if (includeModal(ConsultDoneModal)) {
        closeModal(ConsultDoneModal);
      }

      if (isLogin === true) {
        const neverNotSeen = getNeverNotSeen({
          id: data._id,
          type: data.type,
          isTeacher: data.isTeacher,
          keyOffset: 'done',
        });
        if (neverNotSeen === false) {
          openModal(ConsultDoneModal, { data: data });
        }
      }
    } else {
      closeModal(ConsultDoneModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
}
