import { FC } from 'react';

interface CustomErrorMessageProps {
  children?: any;
}

const CustomErrorMessage: FC<CustomErrorMessageProps> = ({ children }) => {
  if (!children) {
    return null;
  }
  return <p className="mt-1 text-body-2 text-askhow-danger-500">{children}</p>;
};

CustomErrorMessage.defaultProps = {} as Partial<CustomErrorMessageProps>;

export default CustomErrorMessage;
