import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { backIcon } from '../images';

interface HeaderWithBackButtonProps {
  label: string;
  rightButton?: ReactNode;
}

const HeaderWithBackButton: FC<HeaderWithBackButtonProps> = ({
  label,
  rightButton,
}) => {
  const navigate = useNavigate();
  return (
    <div className="bg-askhow-white sticky min-h-[48px] h-12 lg:h-16 top-0 border-b border-b-askhow-black-05 z-10">
      <div className="container mx-auto flex justify-between items-center relative h-full">
        <button
          className="w-5 h-5 lg:w-7 lg:h-7 select-none"
          type="button"
          onClick={() => navigate(-1)}
        >
          <img src={backIcon} alt="뒤로가기" className="w-7" />
        </button>
        <h2 className="text-body-1 text-center font-bold">{label}</h2>
        <div>
          <div className="absolute top-1 bottom-0 right-3">{rightButton}</div>
        </div>
      </div>
    </div>
  );
};

HeaderWithBackButton.defaultProps = {} as Partial<HeaderWithBackButtonProps>;

export default HeaderWithBackButton;
