import React, { DetailsHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import { defaultImage } from '../images';

interface AvartarProps
  extends Omit<
    React.DetailedHTMLProps<
      DetailsHTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'children'
  > {
  uri?: string;
  // size?: string;
  rounded?: boolean;
}
// 기본 96px
export const Avartar: FC<AvartarProps> = ({
  uri = defaultImage,
  className,
  rounded = true,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(
        'block bg-askhow-primary-300 overflow-hidden',
        {
          'rounded-full': rounded === true,
          'rounded-none': rounded === false,
        },
        { 'w-24 h-24': typeof className === 'undefined' },
        className
      )}
    >
      {uri && (
        <img
          src={uri}
          alt="프로필이미지"
          className="object-cover w-full h-full"
        />
      )}
    </div>
  );
};
