import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import PrivateTermContent from "../components/PrivateTermContent";
import ServiceTermContent from "../components/ServiceTermContent";
import { useModals } from "../hooks/useModal";
import { KakaoTalk, primaryLogo } from "../images";
import TermModal from "../Modals/TermModal";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const { openModal } = useModals();
  const openServiceTerm = useCallback(() => {
    openModal(TermModal, {
      content: <ServiceTermContent />,
      label: "서비스이용약관",
    });
  }, [openModal]);
  const openPrivateTerm = useCallback(() => {
    openModal(TermModal, {
      content: <PrivateTermContent />,
      label: "개인정보처리방침",
    });
  }, [openModal]);
  return (
    <footer className=" bg-askhow-black-08 py-20 border-t border-askhow-black-05">
      <div className="container mx-auto flex justify-between">
        <div>
          <div className=" mb-10">
            {/**hidden lg:block */}
            <img src={primaryLogo} alt="Askhow 로고" className="h-8" />
          </div>
          <nav className="flex justify-between mb-8">
            <div className="list-none lg:space-x-8 space-y-4 lg:space-y-0">
              <li className="lg:inline-block">
                <button
                  type="button"
                  onClick={openServiceTerm}
                  className="font-bold text-askhow-black-02 block text-body-2"
                >
                  이용약관
                </button>
              </li>
              <li className="lg:inline-block">
                <button
                  type="button"
                  onClick={openPrivateTerm}
                  className="font-bold text-askhow-black-02 block text-body-2"
                >
                  개인정보처리방침
                </button>
              </li>
              <li className="font-bold text-askhow-black-02 block lg:inline-block text-body-2">
                <Link to="/customer-index/notice">공지사항</Link>
              </li>
              <li className="font-bold text-askhow-black-02 block lg:inline-block text-body-2">
                <Link to="/customer-center">이용안내&문의</Link>
              </li>
            </div>
          </nav>
          <div>
            <p className="text-body-3 text-askhow-black-03 ">
              주식회사 창의와 탐구 | 대표자 : 염만숙 |{" "}
              <span className="text-askhow-black-03 block lg:inline">
                사업자등록번호 : 114-81-84574 |
              </span>{" "}
            </p>
            <p className="text-body-3 text-askhow-black-03 ">
              통신판매번호 : 2008-서울서초-0811 (주)창의와탐구는 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 (주)창의와탐구는 상품, 거래 정보 및 거래에 대하여 책임을 지지 않습니다.
            </p>
            <p className="text-body-3 text-askhow-black-03 ">
              주소 : 서울특별시 서초구 남부순환로 2219(방배동, 방배동나노빌딩
              3층, 5층) | 대표번호 : 02-2033-8800 |{" "}
              <span className="text-askhow-black-03 block lg:inline">
                이메일 : askhow@askwhy.co.kr
              </span>
            </p>
          </div>
          <p className="text-[10px] text-askhow-black-03 mt-4 font-medium">
            주식회사 창의와 탐구 ALL RIGHTS RESERVED
          </p>
          {/* <div className="block lg:hidden text-right mt-10">
          <img
            src={primaryLogo}
            alt="Askhow 로고"
            className="h-8 inline-block"
          />
        </div> */}
        </div>
        <div className="hidden sm:block">
          <div className="flex flex-col justify-center items-center bg-white rounded-10 py-6 px-5 w-[219px] h-[200px]">
            <p className="text-center font-bold text-body-2 text-[#2b2b3e]">
              고객센터
            </p>
            <p className="text-center text-body-3 max-w-[134px] text-[#5A5A6F] mt-2">
              카카오톡 플러스 친구를 통해 고객센터를 운영하고 있으며 버튼을
              누르면 이동합니다.
            </p>
            <div>
              <a
                href="https://pf.kakao.com/_VDuUb"
                className="flex items-center justify-center bg-[#FEE103] px-8 pt-[14px] pb-[10px] rounded-10 space-x-2 mt-3"
              >
                <img src={KakaoTalk} alt="Askhow 로고" className="h-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex justify-center items-center mt-5">
        <div className="flex flex-col justify-center items-center bg-white rounded-10 py-6 px-5 w-[219px] h-[200px]">
          <p className="text-center font-bold text-body-2 text-[#2b2b3e]">
            고객센터
          </p>
          <p className="text-center text-body-3 max-w-[134px] text-[#5A5A6F] mt-2">
            카카오톡 플러스 친구를 통해 고객센터를 운영하고 있으며 버튼을 누르면
            이동합니다.
          </p>
          <div>
            <a
              href="https://pf.kakao.com/_VDuUb"
              className="flex items-center justify-center bg-[#FEE103] px-8 pt-[14px] pb-[10px] rounded-10 space-x-2 mt-3"
            >
              <img src={KakaoTalk} alt="Askhow 로고" className="h-6" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {} as Partial<FooterProps>;

export default Footer;
