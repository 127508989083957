import { useContext, useMemo } from 'react';
import { AuthState, AuthStateContext } from '../contexts/AuthContext';

interface LogOnState {
  isLogin: true;
  authState: AuthState;
}
interface LogOffState {
  isLogin: false;
  authState: {};
}

export function useAuth(): LogOnState | LogOffState {
  const authState = useContext(AuthStateContext);
  const isLogin = useMemo(() => {
    return Object.prototype.hasOwnProperty.call(authState, '_id');
  }, [authState]);

  if (isLogin === true) {
    return {
      isLogin,
      authState: authState as AuthState,
    };
  }
  return {
    isLogin,
    authState,
  };
}
