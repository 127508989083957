import { FC, Fragment, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import FullLoading from '../components/FullLoading';
import { useLayoutState } from '../hooks/useLayoutState';
import Footer from './Footer';
import Header from './Header';
import Menu from './Menu';

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
  const [layoutState] = useLayoutState();

  return (
    <Fragment>
      {layoutState.footer === true || layoutState.header === true ? (
        <Fragment>
          {layoutState.header && <Header />}
          <Menu />
          <main>
            <Suspense fallback={<FullLoading />}>
              <Outlet />
            </Suspense>
          </main>
          {layoutState.footer && <Footer />}
        </Fragment>
      ) : (
        <Suspense fallback={<FullLoading />}>
          <Outlet />
        </Suspense>
      )}
    </Fragment>
  );
};

Layout.defaultProps = {} as Partial<LayoutProps>;

export default Layout;
