import React, { FC, InputHTMLAttributes, useId, useMemo } from 'react';

interface CommonToggleProps
  extends Omit<
    React.DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'placeholder' | 'type' | 'id' | 'hidden' | 'value'
  > {}

const CommonToggle: FC<CommonToggleProps> = ({
  className,
  checked,
  disabled,
  ...rest
}) => {
  const id = useId();
  const location = useMemo(() => {
    return checked ? 'translate-x-4' : 'translate-x-0';
  }, [checked]);
  const bgColor = useMemo(() => {
    return checked ? 'bg-askhow-primary-500' : 'bg-askhow-black-04';
  }, [checked]);

  return (
    <div>
      <label
        htmlFor={id}
        className={`w-[34px] h-[18px] rounded-full inline-block cursor-pointer relative ${bgColor}`}
      >
        <div
          className={`w-[14px] h-[14px] bg-askhow-white rounded-full shadow-askhow-02 absolute top-1/2 -translate-y-1/2 left-[2px] transition-transform ${location}`}
        />
      </label>
      <input
        id={id}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        {...rest}
        hidden
      />
    </div>
  );
};

CommonToggle.defaultProps = {} as Partial<CommonToggleProps>;

export default CommonToggle;
