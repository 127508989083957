import { FC, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avartar } from '../components/Avartar';
import BlockButton from '../components/BlockButton';
import { UserRoleEnum } from '../constants/user-role';
import { useAuth } from '../hooks/useAuth';
import { useAuthDispatch } from '../hooks/useAuthDispatch';
import { useSideMenuState } from '../hooks/useSideMenuState';
import {
  chatConsultIcon,
  closeIconBlack04,
  phoneConsultIcon,
  videoConsultIcon,
} from '../images';

interface MenuProps {}

const Menu: FC<MenuProps> = () => {
  const { isLogin, authState } = useAuth();
  const { logout } = useAuthDispatch();
  const [isOpen, setIsOpen] = useSideMenuState();
  const role = useMemo(() => {
    if (isLogin === false) {
      return UserRoleEnum.NONE;
    }
    return authState.role;
  }, [authState, isLogin]);

  const stopBubbling = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
    },
    []
  );
  const navigate = useNavigate();

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const logoutHandler = useCallback(() => {
    close();
    navigate('/login');
    logout();
  }, [close, logout, navigate]);

  const position = useMemo(() => {
    return isOpen ? 'lg:right-0 left-0' : 'lg:-right-99 -left-99';
  }, [isOpen]);

  return (
    <BackDrop id="side-menu" isOpen={isOpen} onClick={close}>
      <div
        className={`block fixed top-0 bg-askhow-white bottom-0 w-full lg:w-99 transition-all duration-300 ${
          isOpen ? position : position
        } lg:left-auto lg:-left-auto`}
        onClick={stopBubbling}
      >
        <div className="pt-8 pb-6 px-5 lg:px-12 relative">
          <button
            className="w-4 h-4 top-5 right-5 absolute"
            type="button"
            onClick={close}
          >
            <img src={closeIconBlack04} alt="닫기" className="w-4 h-4" />
          </button>

          <div className="flex space-x-3 mb-6 items-center">
            <div>
              <Avartar
                uri={(authState as any).profile_image?.path || undefined}
                className="!w-15.5 !h-15.5 max-w-[62px] max-h-[62px]"
              />
            </div>
            <div className="justify-center items-center">
              <p className="font-bold text-body-1 ">
                {(authState as any).name || '로그인 해주세요.'}
              </p>
              {isLogin && role !== UserRoleEnum.NONE && (
                <div className="space-x-2 flex items-center">
                  <Link
                    to="/mypage/account"
                    onClick={close}
                    className="text-body-2  text-askhow-black-03"
                  >
                    마이페이지
                  </Link>
                  <div className="inline-block h-3 border-r border-askhow-black-05"></div>
                  <button
                    className="text-body-2  text-askhow-black-03"
                    onClick={logoutHandler}
                  >
                    로그아웃
                  </button>
                </div>
              )}
            </div>
          </div>

          {isLogin && role === UserRoleEnum.TEACHER && (
            <BlockButton
              onClick={() => {
                navigate('/mypage/profile');
                close();
              }}
            >
              프로필 관리
            </BlockButton>
          )}
          {isLogin && role !== UserRoleEnum.TEACHER && (
            <BlockButton
              onClick={() => {
                close();
                navigate('/counselling/create');
              }}
            >
              상담글 쓰기
            </BlockButton>
          )}
          <nav className="list-none space-y-4 mt-6">
            {role === UserRoleEnum.NONE && (
              <>
                <li>
                  <BlockButton
                    onClick={() => {
                      navigate('/login');
                      close();
                    }}
                  >
                    로그인 하기
                  </BlockButton>
                </li>
                <li>
                  <Link
                    to="/field"
                    className="font-medium text-body-1 "
                    onClick={close}
                  >
                    전문가 찾기
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search/counselling/newanswer"
                    className="font-medium text-body-1 "
                    onClick={close}
                  >
                    상담사례
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search/edutip/total"
                    className="font-medium text-body-1 "
                    onClick={close}
                  >
                    교육TIP
                  </Link>
                </li>
                <li>
                  <Link
                    to="/customer-center"
                    className="text-body-1  text-askhow-black-03 font-medium"
                    onClick={close}
                  >
                    이용안내&문의
                  </Link>
                </li>
              </>
            )}

            {isLogin &&
              (role !== UserRoleEnum.TEACHER ? (
                <>
                  <li>
                    <Link
                      to="/field"
                      className="font-medium text-body-1 "
                      onClick={close}
                    >
                      전문가 찾기
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/search/counselling/newanswer"
                      className="font-medium text-body-1 "
                      onClick={close}
                    >
                      상담사례
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/search/edutip/total"
                      className="font-medium text-body-1 "
                      onClick={close}
                    >
                      교육TIP
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to="/mypage/reservation"
                      className="font-medium text-body-1 "
                      onClick={close}
                    >
                      상품 관리
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mypage/counselling"
                      className="font-medium text-body-1 "
                      onClick={close}
                    >
                      상담사례 관리
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mypage/edu-tip"
                      className="font-medium text-body-1 "
                      onClick={close}
                    >
                      교육TIP 관리
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/customer-center"
                      className="text-body-1  text-askhow-black-03 font-medium"
                      onClick={close}
                    >
                      이용안내&문의
                    </Link>
                  </li>
                </>
              ))}
          </nav>
        </div>
        {isLogin && role !== UserRoleEnum.TEACHER && (
          <div className="bg-askhow-black-01 py-8 mb-6">
            <nav className="flex justify-center list-none">
              <li className="px-5 border-r border-askhow-black-03">
                <Link to="/search/teacher?toggleChat=true" onClick={close}>
                  <img
                    src={chatConsultIcon}
                    alt="채팅상담"
                    className="w-15.5"
                  />
                  <p className=" text-body-2 font-medium text-center mt-1 text-askhow-white">
                    채팅상담
                  </p>
                </Link>
              </li>
              <li className="px-5 border-r border-askhow-black-03">
                <Link to="/search/teacher?togglePhone=true" onClick={close}>
                  <img
                    src={phoneConsultIcon}
                    alt="전화상담"
                    className="w-15.5"
                  />
                  <p className=" text-body-2 font-medium text-center mt-1 text-askhow-white">
                    전화상담
                  </p>
                </Link>
              </li>
              <li className="px-5">
                <Link to="/search/teacher?toggleScreen=true" onClick={close}>
                  <img
                    src={videoConsultIcon}
                    alt="화상상담"
                    className="w-15.5"
                  />
                  <p className=" text-body-2 font-medium text-center mt-1 text-askhow-white">
                    화상상담
                  </p>
                </Link>
              </li>
            </nav>
          </div>
        )}
        {isLogin && role !== UserRoleEnum.TEACHER && (
          <div className="px-5 lg:px-12">
            <Link
              to="/customer-center"
              className="text-body-1  text-askhow-black-03 font-medium"
              onClick={close}
            >
              이용안내&문의
            </Link>
          </div>
        )}
      </div>
    </BackDrop>
  );
};

Menu.defaultProps = {} as Partial<MenuProps>;

export default Menu;

const BackDrop = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(43, 43, 62, 0.7);
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s;
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
`;
