import { atom } from 'recoil';

export type TopBanner = {
  _id: number;
  type: string;
  title: string;
  startAt: Date;
  payload: any;
  other?: {
    name: string;
    type: 'TEACHER' | 'USER';
  } | null;
  isTeacher: boolean;
} | null;

export const TopBannerState = atom<TopBanner>({
  key: 'TopBannerState',
  default: null,
});
