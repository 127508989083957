import { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ModalsState } from './atoms/ModalsState';

import { useFetchCommingSoonReserve } from './hooks/useCommingSoonReserve';
import { useConsultDoneModal } from './hooks/useConsultDoneModal';
import { useFetchConsultDoneInfo } from './hooks/useFetchConsultDoneInfo';
import { useReserveNotificationModal } from './hooks/useReserveNotificationModal';
import { useSideMenuState } from './hooks/useSideMenuState';
import Routers from './routers';

function App() {
  const [isOpen] = useSideMenuState();
  const [modals] = useRecoilState(ModalsState);
  const prevScroll = useRef(0);

  const action = useNavigationType();
  const location = useLocation();

  useLayoutEffect(() => {
    if (action === 'PUSH' || action === 'REPLACE') {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    const body = document.body;

    if (body) {
      if (isOpen === true || modals.length > 0) {
        const topOffset = window.scrollY;
        prevScroll.current = topOffset;
        body.style.top = `-${topOffset}px`;
        body.classList.add('not-scroll');
      } else {
        body.classList.remove('not-scroll');
        body.style.top = '0px';
        window.scroll({
          top: prevScroll.current,
          behavior: 'auto',
        });
        prevScroll.current = 0;
      }
    }
  }, [isOpen, modals.length]);

  useFetchCommingSoonReserve();
  useFetchConsultDoneInfo();
  useReserveNotificationModal();
  useConsultDoneModal();

  return <Routers />;
}

export default App;
