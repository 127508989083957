import { FC } from 'react';

interface PrivateTermContentProps {}

const PrivateTermContent: FC<PrivateTermContentProps> = () => {
  return (
    <div>
      <section>
        <p className="font-bold text-body-2">
          ㈜창의와탐구(이하 "회사"라 함)는 개인정보보호법, 정보통신망 이용촉진
          및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등
          정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을
          준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익
          보호에 최선을 다하고 있습니다.
        </p>
        <br />
        <p className="font-bold text-body-2">제1조 (개인정보의 처리목적)</p>
        <p className="text-body-2">
          ㈜창의와탐구는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
          있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
          목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를
          받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <div className="ml-4">
          <p className="text-body-2">1. 홈페이지 회원 가입 및 관리</p>
          <p className="text-body-2">
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증,
            회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스
            부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의
            동의여부, 회원 탈퇴 의사 확인 등을 목적으로 개인정보를 처리합니다.
          </p>
          <br />
          <p className="text-body-2">2. 재화 및 서비스의 제공</p>
          <p className="text-body-2">
            서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 입회
            검사 신청 및 양식 작성/접수, 프로그램 상담, 이벤트 정보 및 참여기회
            제공, 통학차량 운행, 교육서비스와 관련된 정보의 제공 등을 목적으로
            개인정보를 처리합니다.
          </p>
          <br />
          <p className="text-body-2">3. 고충처리</p>
          <p className="text-body-2">
            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락. 통지, 처리
            결과의 통보 등의 목적으로 개인정보를 처리합니다.
          </p>
          <br />
          <p className="text-body-2">4. 서비스 이용 기록의 관리</p>
          <p className="text-body-2">
            서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스
            분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를
            이용합니다.
          </p>
          <br />
        </div>
        <br />
        <p className="font-bold text-body-2">
          제2조(개인정보의 처리 및 보유기간)
        </p>
        <p className="text-body-2">
          ㈜창의와탐구는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보
          보유 및 이용기간은 전자상거래 등에서의 소비자 보호에 관한 법률,
          전자금융거래법, 통신비밀보호법 등 법령에 따라 아래와 같이 보유 및 처리
          합니다
        </p>
        <br />
        <div className="ml-3">
          <p className="text-body-2">
            1. 홈페이지 회원 가입 및 관리: 홈페이지 회원 탈퇴시까지
          </p>
          <p className="text-body-2">
            다만 3년간 제공하는 서비스를 이용하지 않은 회원의 개인정보는 별도
            분리 보관하여 관리합니다.
          </p>
          <div className="ml-3">
            <p className="text-body-2">
              1) 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당
              수사․조사 종료시까지
            </p>
            <p className="text-body-2">
              2) 홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당
              채권․채무관계 정산시까지
            </p>
          </div>
          <br />
          <p className="text-body-2">
            2. 재화 또는 서비스 제공: 재화․서비스 공급완료 및 요금결제․정산
            완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간
            종료시까지
          </p>
          <p className="text-body-2">
            1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고,
            계약내용 및 이행 등 거래에 관한 기록
          </p>
          <div className="ml-3">
            <p className="text-body-2">
              - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년
            </p>
            <p className="text-body-2">
              - 소비자 불만 또는 분쟁처리에 관한 기록: 3년
            </p>
          </div>
          <p className="text-body-2">
            2) 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」시행령 제29조에
            따른 본인확인정보 보관: 게시판에 정보 게시가 종료된 후 6개월
          </p>
          <p className="text-body-2">
            3) 관련서비스 제공, 마케팅 및 광고 활용: 3년
          </p>
        </div>
        <br />
        <p className="font-bold text-body-2">
          제3조(정보주체와 법정대리인의 권리․의무 및 행사방법)
        </p>
        <div className="ml-3">
          <p className="text-body-2">
            1. 정보주체는 ㈜창의와탐구에 대해 언제든지 개인정보
            열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수 있습니다.
          </p>
          <p className="text-body-2">
            2. 제1항에 따른 권리 행사는 ㈜창의와탐구에 대해 개인정보보호법
            시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여
            할 수 있으며, ㈜창의와탐구는 이에 대해 지체 없이 조치하겠습니다.
          </p>
          <p className="text-body-2">
            3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자
            등 대리인을 통하여 할 수 있습니다. 이 경우 개인정보 보호법 시행규칙
            별지 제11호 서식에 따른 위임장을 제출해야 합니다.
          </p>
          <p className="text-body-2">
            4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항,
            제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
          </p>
          <p className="text-body-2">
            5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          </p>
          <p className="text-body-2">
            6. ㈜창의와탐구는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
            요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
          </p>
          <p className="text-body-2">
            7. 정보주체는 개인 정보 수집 및 이용에 동의를 거부할 권리가
            있습니다. 단, 동의 거부 시에는 회원가입 서비스를 이용할 수 없습니다.
          </p>
        </div>
        <br />
        <p className="font-bold text-body-2">제4조(개인정보의 제3자 제공)</p>
        <p className="ml-3 text-body-2">
          1. ㈜창의와 탐구는 정보주체의 개인정보를 제1조(개인정보의 처리
          목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의
          특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만
          개인정보를 제3자에게 제공합니다.
        </p>
        <br />
        <p className="font-bold text-body-2">제5조(처리하는 개인정보 항목)</p>
        <p className="text-body">
          ㈜창의와탐구 다음의 개인정보 항목을 처리하고 있습니다.
        </p>
        <div className="ml-3">
          <p className="text-body-2">1. 홈페이지 회원 가입 및 관리</p>
          <div className="ml-3">
            <p className="text-body-2">
              필수항목: 성명, 아이디, 비밀번호, 연락처, 이메일주소
            </p>
            <p className="text-body-2">선택항목: 주소, 학력, 학년</p>
          </div>
          <p className="text-body-2">2. 재화 또는 서비스 제공</p>
          <div className="ml-3">
            <p className="text-body-2">
              필수항목: 이름, 이메일, 자녀정보, (전문가의 경우)학력,경력
            </p>
          </div>
          <p className="text-body-2">
            3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어
            수집될 수 있습니다.
          </p>
          <div className="ml-3">
            <p className="text-body-2">
              IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
            </p>
          </div>
        </div>
        <br />
        <p className="font-bold text-body-2">제6조(개인정보의 파기)</p>
        <div className="ml-3">
          <p className="text-body-2">
            1. ㈜창의와탐구는 개인정보 보유기간의 경과, 처리목적 달성 등
            개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를
            파기합니다.
          </p>
          <p className="text-body-2">
            2. 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나
            처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
            보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
            옮기거나 보관장소를 달리하여 보존합니다.
          </p>
          <p className="text-body-2">
            3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </p>
          <div className="ml-3">
            <p className="text-body-2">1) 파기절차</p>
            <p className="text-body-2">
              파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의
              승인을 받아 개인정보를 파기합니다.
            </p>
            <p className="text-body-2">2) 파기방법 </p>
            <p className="text-body-2">
              전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록
              파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나
              소각하여 파기합니다.
            </p>
          </div>
        </div>
        <br />
        <p className="font-bold text-body-2">
          제7조(개인정보의 안전성 확보조치)
        </p>
        <p className="text-body-2">
          ㈜창의와탐구는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
        </p>
        <div className="ml-3">
          <p className="text-body-2">
            1. 관리적 조치: 내부관리계획 수립․시행, 정기적 직원 교육 등{' '}
          </p>
          <p className="text-body-2">
            2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리,
            접근통제시스템 설치, 보안프로그램 설치{' '}
          </p>
          <p className="text-body-2">
            3. 물리적 조치: 전산실, 자료보관실 등의 접근통제
          </p>
        </div>
        <br />
        <p className="font-bold text-body-2">
          제8조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
        </p>
        <div className="ml-3">
          <p className="text-body-2">
            1. ㈜창의와탐구 이용자에게 개별적인 맞춤서비스를 제공하기 위해
            이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
          </p>
          <p className="text-body-2">
            2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의
            컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
            하드디스크에 저장되기도 합니다.
          </p>
          <div className="ml-3">
            <p className="text-body-2">
              1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
              방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
              이용자에게 최적화된 정보 제공을 위해 사용됩니다.
            </p>
            <p className="text-body-2">
              2) 쿠키의 설치∙운영 및 거부: 웹 브라우저 상단의 도구&gt;인터넷
              옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
              있습니다.
            </p>
            <p className="text-body-2">
              3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
              있습니다.
            </p>
          </div>
        </div>
        <br />
        <p className="font-bold text-body-2">제9조(개인정보 보호책임자)</p>
        <div className="ml-3">
          <p className="text-body-2">
            1. ㈜창의와탐구는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
            개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </p>
          <div className="ml-3">
            <p className="text-body-2">▶ 개인정보 보호책임자</p>
            <p className="text-body-2">성명: 염만숙</p>
            <p className="text-body-2">직책: 대표이사</p>
            <p className="text-body-2">이메일: mulansdream@askwhy.co.kr</p>
          </div>
          <p className="text-body-2">
            2. 정보주체께서는 ㈜창의와탐구의 서비스(또는 사업)을 이용하시면서
            발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한
            사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
          </p>
        </div>
        <br />
        <p className="font-bold text-body-2">제10조(권익침해 구제방법)</p>
        <p className="text-body-2">
          정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
          문의하실 수 있습니다.
        </p>
        <p className="text-body-2">
          아래의 기관은 ㈜창의와탐구와는 별개의 기관으로서, ㈜창의와탐구의
          자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다
          자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
        </p>
        <div className="ml-3">
          <p className="text-body-2">
            1. ㈜창의와탐구는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
            개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
            아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          </p>
          <div className="ml-3">
            <p className="text-body-2">
              ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
            </p>
            <div className="ml-3">
              <p className="text-body-2">
                - 소관업무: 개인정보 침해사실 신고, 상담 신청
              </p>
              <p className="text-body-2">- 홈페이지: privacy.kisa.or.kr </p>
              <p className="text-body-2">- 전화: (국번 없이) 118</p>
              <p className="text-body-2">
                - 주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                개인정보침해신고센터
              </p>
            </div>
            <p className="text-body-2"> ▶ 개인정보 분쟁조정위원회</p>
            <div className="ml-3">
              <p className="text-body-2">
                - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              </p>
              <p className="text-body-2">- 홈페이지: www.kopico.go.kr </p>
              <p className="text-body-2">- 전화: (국번 없이) 1833-6972</p>
              <p className="text-body-2">
                - 주소: (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
              </p>
            </div>
            <p className="text-body-2">
              ▶ 대검찰청 사이버범죄수사단: 02-3480-3573 (www.spo.go.kr)
            </p>
            <p className="text-body-2">
              ▶ 경찰청 사이버안전국: 182 (http://cyberbureau.police.go.kr)
            </p>
          </div>
        </div>
        <br />
      </section>
    </div>
  );
};

PrivateTermContent.defaultProps = {} as Partial<PrivateTermContentProps>;

export default PrivateTermContent;
