import { privateAxiosInstance } from '.';
import { Field } from '../types/field.interface';
import {
  LectureDetailItem,
  lectureEachItem,
} from '../types/lecture-list-item.interface';
import { Media as _Media } from '../types/media.interface';
import { LectureListItemInterface } from '../types/lecture-list-item.interface';

export type LectureListItem = {
  _id: number;
  type: string;
  name: string;
  price: number;
  isPublish: boolean;
  description: string;
  status: string;
};

export type MyPageLectureList =
  | {
      isAgree: false;
    }
  | {
      isAgree: true;
      items: LectureListItem[];
    };

async function getList() {
  const url = '/mypage/lecture';
  const response = await privateAxiosInstance.get(url);
  return response.data as MyPageLectureList;
}

type Media = undefined | File | _Media;

type CommonValue = {
  _id?: number;
  name: string;
  profile: Media;
  field: Field[];
  etcText: string[];
  targetAdult: boolean;
  target: {
    min: { major: string; grade: string };
    max: { major: string; grade: string };
  };
  eachPrice: undefined | number;
  etcPrice: undefined | number;
  description: string;
  etcImage: Media;
  teacherImage: Media;
  teacher_description: string;
  prepareItem: string;
  noticeMessage: string;
};

export type ZoomItemValue = {
  _id?: number;
  no: number;
  date: string;
  description: string;
};

type ZoomValue = {
  type: 'ZOOM';
  maxBuyedCount: undefined | number;
  startTime: {
    hour: undefined | number;
    min: undefined | number;
    AMPM: undefined | 'AM' | 'PM';
  };
  duration: undefined | number;
  items: ZoomItemValue[];
};

export type VODItemValue = {
  _id?: number;
  no: number;
  vodLink: string;
  description: string;
};

type VODValue = {
  type: 'VOD';
  items: VODItemValue[];
};

export type LectureEditValue = CommonValue & (VODValue | ZoomValue);

function lectureValueToFormData(values: LectureEditValue, is_publish: boolean) {
  const fd = new FormData();

  // COMMON
  fd.append('type', values.type);
  fd.append('name', values.name);
  values.field.forEach((v, i) => {
    const name = `field[${i}]`;
    fd.append(name, v._id.toString());
  });
  values.etcText.forEach((v, i) => {
    const name = `etcText[${i}]`;
    fd.append(name, v);
  });
  fd.append('targetAdult', values.targetAdult ? 'true' : 'false');
  fd.append('target[min][major]', values.target.min.major);
  fd.append('target[min][grade]', values.target.min.grade);
  fd.append('target[max][major]', values.target.max.major);
  fd.append('target[max][grade]', values.target.max.grade);

  fd.append('eachPrice', (values.eachPrice ?? 0).toString());
  fd.append('etcPrice', (values.etcPrice ?? 0).toString());
  fd.append('description', values.description);
  fd.append('teacher_description', values.teacher_description);
  fd.append('prepareItem', values.prepareItem);
  fd.append('noticeMessage', values.noticeMessage);
  fd.append('is_publish', is_publish ? 'true' : 'false');

  if (values.profile instanceof File) {
    fd.append('profile', values.profile);
  }
  if (values.etcImage instanceof File) {
    fd.append('etcImage', values.etcImage);
  }
  if (values.teacherImage instanceof File) {
    fd.append('teacherImage', values.teacherImage);
  }

  if (values.type === 'ZOOM') {
    fd.append('maxBuyedCount', (values.maxBuyedCount ?? 0).toString());
    fd.append('duration', (values.duration ?? 0).toString());
    fd.append('startTime[hour]', (values.startTime.hour ?? 0).toString());
    fd.append('startTime[min]', (values.startTime.min ?? 0).toString());
    fd.append('startTime[AMPM]', values.startTime.AMPM ?? 'AM');
    values.items.forEach((item, index) => {
      const _name = `items[${index}]`;
      if (item._id !== undefined) {
        fd.append(`${_name}[_id]`, item._id.toString());
      }
      fd.append(`${_name}[no]`, (index + 1).toString());
      fd.append(`${_name}[date]`, item.date);
      fd.append(`${_name}[description]`, item.description);
    });
  } else {
    values.items.forEach((item, index) => {
      const _name = `items[${index}]`;
      if (item._id !== undefined) {
        fd.append(`${_name}[_id]`, item._id.toString());
      }
      fd.append(`${_name}[no]`, (index + 1).toString());
      fd.append(`${_name}[vodLink]`, item.vodLink);
      fd.append(`${_name}[description]`, item.description);
    });
  }

  return fd;
}

async function createLecture(values: LectureEditValue, is_publish = false) {
  const fd = lectureValueToFormData(values, is_publish);

  const url = '/mypage/lecture';
  const response = await privateAxiosInstance.post(url, fd);
  return response.data as { id: number };
}

async function getOneForEdit(id: string) {
  const url = `/mypage/lecture/${id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data;
}

async function updateLecture(values: LectureEditValue, is_publish = false) {
  const fd = lectureValueToFormData(values, is_publish);

  const url = `/mypage/lecture/${values._id}`;
  const response = await privateAxiosInstance.patch(url, fd);
  return response.data as { id: number };
}
async function deleteLecture(id: any) {
  const url = `/mypage/lecture/${id}`;
  const response = await privateAxiosInstance.delete(url);
  return response.data as { id: number };
}

async function deadlineLecture(id: number) {
  const url = `/mypage/lecture/${id}/deadline`;
  await privateAxiosInstance.patch(url);
}

async function getOneForTeacher(id: number) {
  const url = `/mypage/lecture/teacher/${id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as {
    _id: number;
    name: string;
    status: string;
    type: string;
  };
}

async function getOneForUser(id: number) {
  const url = `/mypage/lecture/user/${id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as {
    _id: number;
    lecture_id: number;
    createdAt: string;
    name: string;
    type: string;
  };
}

export type TeacherVodParticipant = {
  no: number;
  name: String;
  createdAt: string;
  status: string;
};

async function getOneVodParticipantForTeacher(id: number) {
  const url = `/mypage/lecture/teacher/${id}/vod`;
  const response = await privateAxiosInstance.get(url);
  return response.data as {
    rows: TeacherVodParticipant[];
  };
}

async function handleDownlaodParticipant(id: number) {
  privateAxiosInstance({
    url: `/mypage/lecture/teacher/${id}/vod/excel`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    let fileName = '강의신청자목록.xlsx';

    const navigator = (window as any).navigator;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        fileName,
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
}

export type TeacherZoomParticipant = {
  _id: number;
  name: string;
  createdAt: string;
  status: String;
  isAttendance: boolean;
  isCancel: boolean;
  receiveNotification: boolean;
  children: {
    majorCategory: string;
    grade: string;
  }[];
};
export type TeacherZoomDetail = {
  _id: number;
  no: number;
  maxParticipant: number;
  participantCount: number;
  participant: TeacherZoomParticipant[];
};

async function getOneZoomParticipantForTeacher(id: number) {
  const url = `/lecture/teacher/${id}/zoom`;
  const response = await privateAxiosInstance.get(url);
  return response.data as {
    rows: TeacherZoomDetail[];
  };
}

async function updateAttendance(lectureItemId: number, select: number[]) {
  const url = `/lecture/attendance/${lectureItemId}`;
  await privateAxiosInstance.post(url, { users: select });
}

async function receiveJoinNotice(lectureItemId: number, select: number[]) {
  const url = `/lecture/notice/${lectureItemId}`;
  await privateAxiosInstance.post(url, { users: select });
}

async function handleDownlaodZoomParticipant(id: number) {
  privateAxiosInstance({
    url: `/lecture/teacher/${id}/zoom/excel`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    let fileName = '강의신청자목록.xlsx';

    const navigator = (window as any).navigator;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        fileName,
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
}

async function getOne(id: number | string, userId?: number | string) {
  const response = await privateAxiosInstance.get(
    `/lecture/detail?id=${id}${userId ? `&userId=${userId}` : ''}`,
  );
  return response.data as LectureDetailItem;
}

async function getItemOne(id: number) {
  const response = await privateAxiosInstance.get(`/lecture/item/${id}`);
  return response.data as lectureEachItem;
}

async function watchVod(lectureId: number) {
  const response = await privateAxiosInstance.get(
    `/lecture/watch-vod?id=${lectureId}`,
  );
  return response.data as boolean;
}

async function isLectureFull(lectureId: number) {
  const response = await privateAxiosInstance.get(
    `/lecture/is-lecture-full?id=${lectureId}`,
  );
  return response.data as boolean;
}

async function watchVodWithItem(itemId: number) {
  const response = await privateAxiosInstance.get(
    `/lecture/watch-vod-with-item?id=${itemId}`,
  );
  return response.data as boolean;
}

async function getHomeList() {
  const response = await privateAxiosInstance.get('/lecture/home');
  return response.data as LectureListItemInterface[];
}
export type UserLectureItem = {
  _id: number;
  no: number;
  period: string;
  price: number;
  isJoined: boolean;
  joinUrl: string;
  status: string;
  hasReview: boolean;
  item_id: number;
};

async function getUserLectureItems(ticketId: number) {
  const url = `/lecture/user/ticket/${ticketId}/zoom`;
  const response = await privateAxiosInstance.get(url);
  return response.data as {
    rows: UserLectureItem[];
  };
}

async function createReview(
  ticket: number,
  item: number,
  star: number,
  content: string,
) {
  const url = `/lecture/user/ticket/${ticket}/review`;
  await privateAxiosInstance.post(url, {
    item_id: item,
    star,
    text: content,
  });
}

async function cancelItem(ticketId: number, itemId: number) {
  const url = `/lecture/user/ticket/${ticketId}/item/${itemId}`;
  await privateAxiosInstance.delete(url);
}

export const lectureApis = {
  getList,
  createLecture,
  getOneForEdit,
  updateLecture,
  deleteLecture,
  deadlineLecture,
  getOneForTeacher,
  getOneVodParticipantForTeacher,
  handleDownlaodParticipant,
  getOneZoomParticipantForTeacher,
  updateAttendance,
  receiveJoinNotice,
  handleDownlaodZoomParticipant,
  getOne,
  getItemOne,
  watchVod,
  watchVodWithItem,
  getHomeList,
  getOneForUser,
  getUserLectureItems,
  createReview,
  cancelItem,
  isLectureFull,
};
