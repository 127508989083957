import * as Yup from 'yup';

export const qnaPostYup = Yup.object().shape({
  // file: Yup.mixed().required(
  //   'jpg, png, pdf로 된 통장등본을 업로드해주세요.'
  // ),
  title: Yup.string().trim().required('제목을 입력해주세요.'),
  qnaField: Yup.string()
    .required('분야를 선택해주세요.')
    .typeError('분야를 선택해주세요.'),
  content: Yup.string().required('내용을 입력해주세요.'),
});
