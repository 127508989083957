import { useEffect } from 'react';
import { useLayoutState } from './useLayoutState';

export function useLayoutHide(
  opts: { header: boolean; footer: boolean } = { header: false, footer: false },
) {
  const [, setHeaderShow] = useLayoutState();
  useEffect(() => {
    setHeaderShow(opts);
    return () => {
      setHeaderShow({
        footer: true,
        header: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeaderShow]);
}
