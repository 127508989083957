import { FC, ReactNode, useMemo } from 'react';

interface WhiteBoardProps {
  className?: string;
  children?: ReactNode;
}

const WhiteBoard: FC<WhiteBoardProps> = ({ children, className }) => {
  const _classes = useMemo(() => {
    let _className =
      'lg:w-99 px-6 py-5 lg:py-10 lg:px-12 rounded-10 mx-auto shadow-askhow-01 bg-askhow-white overflow-hidden';
    if (className) {
      _className += ' ' + className;
    }
    return _className;
  }, [className]);
  return <div className={_classes}>{children}</div>;
};

WhiteBoard.defaultProps = {} as Partial<WhiteBoardProps>;

export default WhiteBoard;
