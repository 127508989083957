import { FC, useCallback } from 'react';

import { useModals } from '../hooks/useModal';
import CustomModal from './components/CustomModal';

interface Button {
  text?: string;
  onClose?: () => void;
  color?: 'secondary' | 'primary';
}

interface AlertModalProps {
  content?: string | React.ReactNode;
  buttons?: Button[];
  onRequestClose?: () => void;
}

const AlertModal: FC<AlertModalProps> = ({
  content = '내용을 입력해주세요.',
  buttons = [{ text: '확인', color: 'primary' }],
  onRequestClose,
}) => {
  const { closeModal } = useModals();

  const onClose = useCallback(
    (closeEvent?: () => void) => {
      closeModal(AlertModal);
      closeEvent && closeEvent();
    },
    [closeModal]
  );

  return (
    <CustomModal onRequestClose={onRequestClose}>
      <div className="py-10 text-center font-medium break-words px-2">
        {content}
      </div>
      <div className="flex">
        {buttons.map((button) => {
          const color =
            (button.color || 'primary') === 'primary'
              ? 'bg-askhow-primary-500'
              : 'bg-askhow-black-05';
          return (
            <button
              type="button"
              className={`flex-1 ${color} text-askhow-white h-12 font-medium`}
              onClick={() => onClose(button.onClose)}
            >
              {button.text}
            </button>
          );
        })}
      </div>
    </CustomModal>
  );
};

AlertModal.defaultProps = {} as Partial<AlertModalProps>;

export default AlertModal;
