import { FC, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { ModalsState } from '../atoms/ModalsState';

export function useModals() {
  const [modals, setModal] = useRecoilState(ModalsState);

  const open = useCallback(
    <P extends {}>(Component: FC<P>, props: P) => {
      setModal((modals) => {
        return [...modals, { Component, props }];
      });
    },
    [setModal]
  );

  const close = useCallback(
    <P extends {}>(Component: FC<P>) => {
      setModal((modals) => {
        return modals.filter((modal) => {
          return modal.Component !== Component;
        });
      });
    },
    [setModal]
  );

  const include = useCallback(
    <P extends {}>(Component: FC<P>) => {
      const index = modals.findIndex((v) => v.Component === Component);
      return index > -1;
    },
    [modals]
  );

  const dispatch = useMemo(() => {
    return { openModal: open, closeModal: close, includeModal: include };
  }, [close, include, open]);

  return dispatch;
}
