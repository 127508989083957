export function setNeverNotSeen(opts: {
  id: number;
  type: string;
  isTeacher: boolean;
  keyOffset?: string;
  next: boolean;
}) {
  const { id, isTeacher, next, type, keyOffset = '' } = opts;
  const key = `is_never_not_seen${keyOffset}_${id}_${type}_${
    isTeacher ? 'teacher' : 'user'
  }`;
  if (window) {
    window.localStorage.setItem(key, JSON.stringify(next));
    return next;
  }
  return next;
}
export function getNeverNotSeen(opts: {
  id: number;
  type: string;
  isTeacher: boolean;
  keyOffset?: string;
}) {
  const { id, isTeacher, type, keyOffset = '' } = opts;
  const key = `is_never_not_seen${keyOffset}_${id}_${type}_${
    isTeacher ? 'teacher' : 'user'
  }`;
  if (window) {
    const value = JSON.parse(window.localStorage.getItem(key) || 'false');
    return value;
  }
  return false;
}
