import { FC, ReactNode } from 'react';

interface NodataProps {
  children?: ReactNode;
  className?: string;
  textClassName?: string;
}

const Nodata: FC<NodataProps> = ({
  children,
  className = '',
  textClassName = '',
}) => {
  return (
    <div className={`flex justify-center items-center py-60 ${className}`}>
      <p
        className={`font-medium text-h3 text-askhow-black-03 ${textClassName}`}
      >
        {children}
      </p>
    </div>
  );
};

Nodata.defaultProps = {} as Partial<NodataProps>;

export default Nodata;
