import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KeywordResponse, searchApis } from '../axios/Search.apis';
import { useModals } from '../hooks/useModal';
import { backIcon, searchIcon } from '../images';
import { TopAlignModal } from './components/CustomModal';

interface SearchModalProps {}

const SearchModal: FC<SearchModalProps> = () => {
  const navigate = useNavigate();
  const { closeModal } = useModals();
  const [searchWord, setSearchWord] = useState('');
  const [searchHistory, setSearchHistory] = useState<
    KeywordResponse[] | null
  >();
  const close = useCallback(() => {
    closeModal(SearchModal);
  }, [closeModal]);

  const handleSearch = useCallback(
    (search: string) => {
      navigate('/search/all', { state: search });
      search.length > 0 && searchApis.postKeyword(search).catch(() => {});
      close();
    },
    [close, navigate]
  );

  useEffect(() => {
    searchApis
      .getKeyword()
      .then((data) => {
        setSearchHistory(data);
      })
      .catch(() => {});
  }, []);

  return (
    <TopAlignModal
      onRequestClose={() => {
        close();
      }}
      className="absolute w-full left-0 top-0 bottom-0 right-0 lg:bottom-auto lg:right-auto  lg:left-1/2 lg:-translate-x-1/2 lg:top-5 lg:w-[482px] align-top lg:rounded-10 bg-askhow-white overflow-hidden shadow-askhow-01"
    >
      <div className="border-b-2 py-3 px-5 lg:py-0 lg:px-0 lg:mx-4 lg:mt-[7px] flex leading-7 items-center">
        <button
          type="button"
          onClick={close}
          className="inline-block lg:hidden mr-2"
        >
          <img src={backIcon} alt="뒤로가기" className="inline-block w-6" />
        </button>
        <input
          autoFocus
          type="search"
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder="검색어를 입력하세요."
          className="inline-block font-medium placeholder:text-askhow-black-01 text-askhow-black-01 outline-0 flex-1"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch(searchWord);
            }
          }}
          maxLength={100}
        />
        <button onClick={() => handleSearch(searchWord)}>
          <img className="w-6 h-6 lg:w-5 lg:h-5" src={searchIcon} alt="On" />
        </button>
      </div>
      <div className="mb-4 pr-4">
        {searchHistory &&
          searchHistory.map((el) => {
            return (
              <div key={el._id}>
                <button
                  className="h-9 w-[100%] flex"
                  onClick={() => {
                    handleSearch(el.text);
                  }}
                >
                  <div className="ml-4 mt-4 text-body-2 line-clamp-1 ">
                    {el.text}
                  </div>
                  <div className="mt-5 inline text-body-3 font-medium text-askhow-black-03 pl-1">
                    {el.createdAt}
                  </div>
                </button>
              </div>
            );
          })}
      </div>
    </TopAlignModal>
  );
};

SearchModal.defaultProps = {} as Partial<SearchModalProps>;

export default SearchModal;
