import { FC, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserRoleEnum } from '../constants/user-role';
import { Layout } from '../layout';
import { useAuth } from '../hooks/useAuth';
import { AuthState } from '../contexts/AuthContext';
import RoleBasedRouter from '../layout/RoleBasedRouter';
import { useNotificationSocket } from '../hooks/useNotificationSocket';
import QnaEdit from './customer-center/QnaEdit';

const PresentationDetail = lazy(
  () => import('./authorized/MyPage/PresentationDetail')
);
const MypageLectureDetail = lazy(
  () => import('./authorized/MyPage/LectureDetail')
);

const Coupons = lazy(() => import('./authorized/MyPage/Coupons'));
const MyPage = lazy(() => import('./authorized/MyPage'));
const MyPageAccount = lazy(() => import('./authorized/MyPage/MyPageAccount'));
const Home = lazy(() => import('./home'));
const NotFound = lazy(() => import('./NotFound'));
const Unauthorized = lazy(() => import('./Unauthorized'));
const FindAccount = lazy(() => import('./unauthorized/find-account'));
const FindAccountDone = lazy(() => import('./unauthorized/find-account-done'));
const FindPw = lazy(() => import('./unauthorized/find-pw'));
const FindPwReset = lazy(() => import('./unauthorized/find-pw-reset'));
const Login = lazy(() => import('./unauthorized/login'));
const Register = lazy(() => import('./unauthorized/register'));
const RegisterDone = lazy(() => import('./unauthorized/register-done'));
const RegisterSocial = lazy(() => import('./unauthorized/register-social'));
const RegisterType = lazy(() => import('./unauthorized/register-type'));
const TeacherDetail = lazy(() => import('./teacher-detail'));
const TeacherCounsellingDetail = lazy(
  () => import('./components/TeacherCounsellingDetail')
);
const EditTeacherProfile = lazy(
  () => import('./authorized/MyPage/EditTeacherProfile')
);
const Products = lazy(() => import('./authorized/MyPage/Products'));
const ConsultTimeTableEdit = lazy(
  () => import('./authorized/MyPage/ConsultTimeTableEdit')
);
const ReservationList = lazy(
  () => import('./authorized/MyPage/ReservationList')
);
const ReservationDetail = lazy(
  () => import('./authorized/MyPage/ReservationDetail')
);
const Revenue = lazy(() => import('./authorized/MyPage/Revenue'));
const TeacherReservation = lazy(
  () => import('./authorized/TeacherReservation')
);
const PresentationReservation = lazy(
  () => import('./authorized/PresentationReservation')
);
const LectureReservation = lazy(
  () => import('./authorized/LectureReservation')
);
const LectureVod = lazy(() => import('./authorized/LectureVod'));

const ChatRoom = lazy(() => import('./authorized/ChatRoom'));
const Payments = lazy(() => import('./authorized/MyPage/Payments'));
const Counselling = lazy(() => import('./authorized/MyPage/Counselling'));
const CreateCounselling = lazy(() => import('./authorized/CreateCounselling'));
const EduTip = lazy(() => import('./authorized/MyPage/EduTip'));
const CreateEduTip = lazy(() => import('./authorized/CreateEduTip'));
const LectureDetail = lazy(() => import('./LectureDetail'));
const EduTipDetail = lazy(() => import('./EduTipDetail'));
const EditEduTip = lazy(() => import('./authorized/EditEduTip'));
const CustomerCenter = lazy(() => import('./customer-center/CustomerCenter'));
const CustomerIndex = lazy(() => import('./customer-center/CustomerIndex'));
const Faq = lazy(() => import('./customer-center/Faq'));
const Qna = lazy(() => import('./customer-center/Qna'));
const NoticeDetail = lazy(() => import('./customer-center/NoticeDetail'));
const FaqDetail = lazy(() => import('./customer-center/FaqDetail'));
const QnaDetail = lazy(() => import('./customer-center/QnaDetail'));
const Notice = lazy(() => import('./customer-center/notice'));
const QnaCreate = lazy(() => import('./customer-center/QnaCreate'));
const Bookmark = lazy(() => import('./authorized/MyPage/Bookmark'));
const DetailCounselling = lazy(() => import('./DetailCounselling'));
const CreateAnswer = lazy(() => import('./CreateAnswer'));
const Notification = lazy(() => import('./authorized/MyPage/Notification'));
const ChangeReservation = lazy(() => import('./authorized/ChangeReservation'));
const SearchIndex = lazy(() => import('./authorized/Search/SearchIndex'));
const EduTipIndex = lazy(() => import('./authorized/Search/EduTipIndex'));
const FieldIndex = lazy(() => import('./authorized/Search/FieldIndex'));
const AllIndex = lazy(() => import('./authorized/Search/AllIndex'));
const Total = lazy(() => import('./authorized/Search/eduTipComponents/Total'));
const ChildEducation = lazy(
  () => import('./authorized/Search/eduTipComponents/ChildEducation')
);
const ElemantaryEducation = lazy(
  () => import('./authorized/Search/eduTipComponents/ElemantaryEducation')
);
const MiddleEducation = lazy(
  () => import('./authorized/Search/eduTipComponents/MiddleEducation')
);
const HighEducation = lazy(
  () => import('./authorized/Search/eduTipComponents/HighEducation')
);
const HighEntranceEducation = lazy(
  () => import('./authorized/Search/eduTipComponents/HighEntranceEducation')
);
const UniversityEntranceEducation = lazy(
  () =>
    import('./authorized/Search/eduTipComponents/UniversityEntranceEducation')
);
const Etc = lazy(() => import('./authorized/Search/eduTipComponents/Etc'));
const CounsellingIndex = lazy(
  () => import('./authorized/Search/CounsellingIndex')
);
const PresentationIndex = lazy(
  () => import('./authorized/Search/PresentationIndex')
);
const LectureIndex = lazy(() => import('./authorized/Search/LectureIndex'));
const SortLectureNewest = lazy(
  () => import('./authorized/Search/lectureComponents/SortLectureNewest')
);
const SortLectureDeadline = lazy(
  () => import('./authorized/Search/lectureComponents/SortLectureDeadline')
);
const SortLectureView = lazy(
  () => import('./authorized/Search/lectureComponents/SortLectureView')
);

const SortNewAnswer = lazy(
  () => import('./authorized/Search/counsellingComponents/SortNewAnswer')
);
const SortNewCounselling = lazy(
  () => import('./authorized/Search/counsellingComponents/SortNewCounselling')
);
const SortViewOrder = lazy(
  () => import('./authorized/Search/counsellingComponents/SortViewOrder')
);
const TeacherIndex = lazy(() => import('./authorized/Search/TeacherIndex'));
// import QnaDetail from './customer-center/QnaDetail';
const PresentationTeacherDetail = lazy(
  () => import('./authorized/MyPage/PresentationTeacherDetail')
);
const SortNewest = lazy(
  () => import('./authorized/Search/presentationComponents/SortNewest')
);
const SortDeadline = lazy(
  () => import('./authorized/Search/presentationComponents/SortDeadline')
);
const CraeteLecture = lazy(() => import('./authorized/MyPage/CraeteLecture'));
const EditLecture = lazy(() => import('./authorized/MyPage/EditLecture'));
const LectureTeacherDetail = lazy(
  () => import('./authorized/MyPage/LectureTeacherDetail')
);

interface RoutersProps {}

const Routers: FC<RoutersProps> = () => {
  const { authState } = useAuth() as { authState: AuthState };
  useNotificationSocket();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routers */}
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="/" element={<Home />} />
        <Route path="/teacher/:id" element={<TeacherDetail />} />
        <Route
          path="/teacher/teachercounsellingdetail/:id"
          element={<TeacherCounsellingDetail />}
        />
        <Route path="/lecture/:id" element={<LectureDetail />} />
        <Route path="/edu-tip/:id" element={<EduTipDetail />} />
        <Route path="/customer-center" element={<CustomerCenter />} />
        <Route path="/counselling/:id" element={<DetailCounselling />} />
        <Route path="/customer-index" element={<CustomerIndex />}>
          <Route path="/customer-index/notice" element={<Notice />} />
          <Route path="/customer-index/faq" element={<Faq />} />
          <Route path="/customer-index/qna" element={<Qna />} />
        </Route>
        <Route path="/customer-index/notice/:id" element={<NoticeDetail />} />
        <Route path="/customer-index/faq/:id" element={<FaqDetail />} />
        <Route path="/customer-index/qna/:id" element={<QnaDetail />} />
        <Route path="/field" element={<FieldIndex />} />
        <Route path="/search" element={<SearchIndex />}>
          <Route path="/search/all" element={<AllIndex />} />
          <Route path="/search/edutip" element={<EduTipIndex />}>
            <Route path="/search/edutip/total" element={<Total />} />
            <Route
              path="/search/edutip/child-education"
              element={<ChildEducation />}
            />
            <Route
              path="/search/edutip/elementary-education"
              element={<ElemantaryEducation />}
            />
            <Route
              path="/search/edutip/middle-education"
              element={<MiddleEducation />}
            />
            <Route
              path="/search/edutip/high-education"
              element={<HighEducation />}
            />
            <Route
              path="/search/edutip/high-enterance-education"
              element={<HighEntranceEducation />}
            />
            <Route
              path="/search/edutip/university-entrance-education"
              element={<UniversityEntranceEducation />}
            />
            <Route path="/search/edutip/etc" element={<Etc />} />
          </Route>
          <Route path="/search/counselling" element={<CounsellingIndex />}>
            <Route
              path="/search/counselling/newanswer"
              element={<SortNewAnswer />}
            />
            <Route
              path="/search/counselling/newcounselling"
              element={<SortNewCounselling />}
            />
            <Route
              path="/search/counselling/vieworder"
              element={<SortViewOrder />}
            />
          </Route>
          <Route path="/search/presentation" element={<PresentationIndex />}>
            <Route
              path="/search/presentation/newest"
              element={<SortNewest />}
            />
            <Route
              path="/search/presentation/deadline"
              element={<SortDeadline />}
            />
          </Route>
          <Route path="/search/lecture" element={<LectureIndex />}>
            <Route
              path="/search/lecture/deadline"
              element={<SortLectureDeadline />}
            />
            <Route
              path="/search/lecture/newest"
              element={<SortLectureNewest />}
            />
            <Route path="/search/lecture/view" element={<SortLectureView />} />
          </Route>
          <Route path="/search/teacher" element={<TeacherIndex />} />
        </Route>

        {/* require Not authorized */}
        <Route element={<RoleBasedRouter role={[UserRoleEnum.NONE]} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register-type" element={<RegisterType />} />
          <Route path="/register-social" element={<RegisterSocial />} />
          <Route path="/register" element={<Register />} />
          <Route path="/find-account" element={<FindAccount />} />
          <Route path="/find-account-done" element={<FindAccountDone />} />
          <Route path="/find-pw" element={<FindPw />} />
          <Route path="/find-pw/reset" element={<FindPwReset />} />
        </Route>

        {/* require authorized */}
        <Route
          element={
            <RoleBasedRouter role={[UserRoleEnum.UNDER, UserRoleEnum.UPPER]} />
          }
        >
          <Route path="/teacher/:id/reserve" element={<TeacherReservation />} />
          <Route
            path="/presentation/:id/reserve"
            element={<PresentationReservation />}
          />
          <Route path="/lecture/vod/:id" element={<LectureVod />} />
          <Route path="/lecture/:id/reserve" element={<LectureReservation />} />
          <Route path="/counselling/create" element={<CreateCounselling />} />
        </Route>
        {/* 선생 전용 */}
        <Route element={<RoleBasedRouter role={[UserRoleEnum.TEACHER]} />}>
          <Route path="/counselling/answer" element={<CreateAnswer />} />
          <Route path="/edu-tip/:id/edit" element={<EditEduTip />} />
          <Route
            path="/mypage/profile"
            element={<TeacherDetail accountId={authState?._id} />}
          />
          <Route
            path="/mypage/profile/edit"
            element={<EditTeacherProfile accountId={authState?._id} />}
          />
          <Route
            path="/mypage/consult-time-table"
            element={<ConsultTimeTableEdit />}
          />
          <Route path="/edu-tip/create" element={<CreateEduTip />} />
          <Route path="/lecture/create" element={<CraeteLecture />} />
          <Route path="/lecture/edit/:id" element={<EditLecture />} />
          <Route element={<MyPage />}>
            <Route path="/mypage/products" element={<Products />} />
            <Route path="/mypage/revenue" element={<Revenue />} />
            <Route path="/mypage/edu-tip" element={<EduTip />} />
          </Route>
        </Route>
        {/* 일반회원전용 */}
        <Route
          element={
            <RoleBasedRouter role={[UserRoleEnum.UNDER, UserRoleEnum.UPPER]} />
          }
        >
          <Route element={<MyPage />}>
            <Route path="/mypage/coupon" element={<Coupons />} />
          </Route>
        </Route>
        {/* 전체회원전용 */}
        <Route
          element={
            <RoleBasedRouter
              role={[
                UserRoleEnum.UNDER,
                UserRoleEnum.UPPER,
                UserRoleEnum.TEACHER,
              ]}
            />
          }
        >
          <Route path="/register/done" element={<RegisterDone />} />
          <Route path="/reservation-change" element={<ChangeReservation />} />
          <Route path="/chatroom" element={<ChatRoom />} />
          <Route path="/customer-index/qna/create" element={<QnaCreate />} />
          <Route path="/customer-index/qna/:id/edit" element={<QnaEdit />} />
        </Route>
        <Route
          element={
            <RoleBasedRouter
              role={[
                UserRoleEnum.UNDER,
                UserRoleEnum.UPPER,
                UserRoleEnum.TEACHER,
              ]}
            />
          }
        >
          <Route path="/mypage" element={<MyPage />}>
            <Route path="/mypage/account" element={<MyPageAccount />} />
            <Route path="/mypage/reservation" element={<ReservationList />} />
            <Route
              path="/mypage/reservation/:id"
              element={<ReservationDetail />}
            />
            <Route path="/mypage/counselling" element={<Counselling />} />
            <Route path="/mypage/notification" element={<Notification />} />
            <Route
              path="/mypage/presentation/:id"
              element={<PresentationDetail />}
            />
            <Route
              path="/mypage/lecture/:id"
              element={<MypageLectureDetail />}
            />
            <Route
              element={
                <RoleBasedRouter
                  role={[UserRoleEnum.UPPER, UserRoleEnum.UNDER]}
                />
              }
            >
              <Route path="/mypage/payment" element={<Payments />} />
              <Route path="/mypage/bookmark" element={<Bookmark />} />
            </Route>
          </Route>
        </Route>

        {/* fallback */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

Routers.defaultProps = {} as Partial<RoutersProps>;

export default Routers;
