import { FC } from 'react';
import Button, { ButtonProps } from './Button';

interface BlockButtonProps extends ButtonProps {
  color?: 'primary' | 'secondary' | 'gray' | 'black';
}

const BlockButton: FC<BlockButtonProps> = ({
  className,
  color = 'primary',
  ...props
}) => {
  return (
    <Button
      {...props}
      className={`block ${
        color === 'primary'
          ? 'bg-askhow-primary-500'
          : color === 'secondary'
          ? 'bg-askhow-black-02'
          : color === 'gray'
          ? 'bg-askhow-black-05'
          : 'bg-askhow-black-01'
      } w-full rounded h-12.5 text-body-2 text-askhow-white font-medium ${
        className || ''
      }`}
    />
  );
};

BlockButton.defaultProps = {} as Partial<BlockButtonProps>;

export default BlockButton;
