import { privateAxiosInstance } from '.';
import { TopBanner } from '../atoms/TopBannerState';

async function getJoinInfo(consultId: number) {
  const response = await privateAxiosInstance.get(`/consult/${consultId}/join`);
  return response.data as JoinInfo;
}

async function createReview(consultId: number, rate: number, text: string) {
  const response = await privateAxiosInstance.post(
    `/consult/${consultId}/review`,
    {
      rate,
      text,
    }
  );
  return response.data;
}

async function getChatJoinInfo(consultId: number) {
  const response = await privateAxiosInstance.get(
    `/consult/${consultId}/chatjoin`
  );
  return response.data as JoinInfo;
}

export const consultApis = { getJoinInfo, createReview, getChatJoinInfo };

export type JoinInfo =
  | {
      success: false;
      errorMsg: string;
    }
  | {
      success: true;
      response: JoinInfoResponse;
    };
type JoinInfoResponse =
  | {
      type: 'chat';
      payload: number;
    }
  | {
      type: 'phone';
      payload: TopBanner;
    }
  | {
      type: 'video';
      payload: string;
    };
