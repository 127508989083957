import { privateAxiosInstance } from '.';
import { Notification } from '../atoms/NotificationState';
import { PaginateData } from '../types/paginate-data.interface';

async function readNotification(notificationId: number) {
  const response = await privateAxiosInstance.post(
    `/notification/${notificationId}/read`
  );
  return response.data as {
    unReadCount: number;
  };
}

async function readAllNotification() {
  const response = await privateAxiosInstance.post(`/notification/all/read`);
  return response.data as {
    unReadCount: number;
  };
}

async function getList(page: number, filter: 'total' | 'read' | 'unread') {
  const url = `/notification?page=${page}&filter=${filter}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as PaginateData<Notification>;
}

export const notificationApis = {
  readNotification,
  getList,
  readAllNotification,
};
