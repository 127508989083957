import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { TopBannerState } from '../atoms/TopBannerState';
import { UserRoleEnum } from '../constants/user-role';
import ReserveNotificationModal from '../Modals/ReserveNotificationModal';
import { useAuth } from './useAuth';
import { useModals } from './useModal';
import { getNeverNotSeen } from '../utils/never-seen';

export function useReserveNotificationModal() {
  const { isLogin, authState } = useAuth();
  const [topBanner] = useRecoilState(TopBannerState);
  const { closeModal, includeModal, openModal } = useModals();

  useEffect(() => {
    if (topBanner) {
      if (includeModal(ReserveNotificationModal)) {
        closeModal(ReserveNotificationModal);
      }
      if (isLogin === true) {
        if (authState.role !== UserRoleEnum.TEACHER) {
          if (topBanner.type === 'phone') {
            return;
          }
        }
        const neverNotSeen = getNeverNotSeen({
          id: topBanner._id,
          type: topBanner.type,
          isTeacher: topBanner.isTeacher,
        });

        if (neverNotSeen === false) {
          openModal(ReserveNotificationModal, { value: topBanner });
        }
      }
    } else {
      closeModal(ReserveNotificationModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBanner]);
}
