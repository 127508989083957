import { Presentation } from './../routers/authorized/PresentationReservation';
import { privateAxiosInstance } from '.';
import { ConsultDoneInfo } from '../atoms/RequireReviewState';
import { TopBanner } from '../atoms/TopBannerState';
import { CouponBenefitEnum } from '../enums/CouponBenefit.enum';
import { FieldData } from '../routers/authorized/components/TeacherReservation';
import { TimeSlot } from '../types/timeslot';

export interface GetTeacherInfoResponse {
  teacher_name: string;
  teacher_field: FieldData[];
  teacher_etc_text: string[];
  product: { _id: number; title: string; price: number }[];
  consultableTime: {
    monday: null | TimeSlot;
    tuesday: null | TimeSlot;
    wendsday: null | TimeSlot;
    thursday: null | TimeSlot;
    friday: null | TimeSlot;
    setarday: null | TimeSlot;
    sunday: null | TimeSlot;
  };
}

async function getTeacherInfo(id: number) {
  const response = await privateAxiosInstance.get(`/reserve/${id}/info`);
  return response.data as GetTeacherInfoResponse;
}

async function getReserveTimes(id: number, date: string) {
  const response = await privateAxiosInstance.get(
    `/reserve/${id}/reserve-time?date=${date}`,
  );
  return response.data as string[];
}

async function payComplete(imp_uid: string) {
  const response = await privateAxiosInstance.post(`/payment/complete`, {
    imp_uid,
  });
  return response.data;
}

interface ReservationData {
  productId: number;
  reserveAt: string;
  couponId?: number;
  buyerId: number;
  total: number;
  discount: number;
  field: number[];
  etcText: string[];
}

interface ReservePresentationData {
  presentationId: number;
  couponId?: number;
  buyerId: number;
  total: number;
  discount: number;
}

interface ReserveLectureData {
  lectureId: number;
  couponId?: number;
  buyerId: number;
  total: number;
  discount: number;
}

async function freePayComplete(
  data: ReservationData,
  merchant_uid: string,
  content: string,
  attatchedFile: File | null,
) {
  const fd = new FormData();
  fd.append('productId', `${data.productId}`);
  fd.append('reserveAt', data.reserveAt);
  if (data.couponId) {
    fd.append('couponId', `${data.couponId}`);
  }
  fd.append('buyerId', `${data.buyerId}`);
  fd.append('total', `${data.total}`);
  fd.append('discount', `${data.discount}`);
  data.field.forEach((v, i) => {
    fd.append(`field[${i}]`, `${v}`);
  });
  data.etcText.forEach((v, i) => {
    fd.append(`etcText[${i}]`, v);
  });
  fd.append('merchant_uid', merchant_uid);
  fd.append('content', content);

  if (attatchedFile) {
    fd.append('file', attatchedFile);
  }
  const response = await privateAxiosInstance.post(
    `/payment/free-complete`,
    fd,
  );
  return response.data;
}

async function acceptReserve(id: number) {
  await privateAxiosInstance.post(`/reserve/${id}/accept`);
  return 'good';
}

async function rejectReserve(id: number, content: string) {
  await privateAxiosInstance.post(`/reserve/${id}/reject`, {
    content,
  });
  return 'good';
}

async function cancelReserve(id: number, content: string) {
  await privateAxiosInstance.post(`/reserve/${id}/cancel`, {
    content,
  });
  return 'good';
}

async function getCommingSoon() {
  const response = await privateAxiosInstance.get(`/reserve/comming-soon`);

  return response.data as {
    result: TopBanner;
  };
}

async function getRequireReview() {
  const response = await privateAxiosInstance.get(`/reserve/require-review`);

  return response.data as {
    result: ConsultDoneInfo;
  };
}

async function getAllCouponList() {
  const response = await privateAxiosInstance.get(`/reserve/coupon`);

  return response.data as {
    rows: {
      _id: number;
      name: string;
      benefit: CouponBenefitEnum;
    }[];
  };
}

async function getSpecificCouponList(usefor: string) {
  const response = await privateAxiosInstance.get(
    `/reserve/specific-coupon?useFor=${usefor}`,
  );
  return response.data as {
    rows: {
      _id: number;
      name: string;
      benefit: CouponBenefitEnum;
      value: number;
    }[];
  };
}

async function getChangableDate(id: number) {
  const response = await privateAxiosInstance.get(
    `/reserve/${id}/changable-date`,
  );
  return response.data as {
    monday: null | TimeSlot;
    tuesday: null | TimeSlot;
    wendsday: null | TimeSlot;
    thursday: null | TimeSlot;
    friday: null | TimeSlot;
    setarday: null | TimeSlot;
    sunday: null | TimeSlot;
  };
}

async function getNotChangableTime(consultId: number, date: string) {
  const url = `/reserve/${consultId}/consult-reserve-time?date=${date}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as string[];
}

async function getReserveInfo(consultId: number) {
  const url = `/reserve/${consultId}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as {
    length: number;
    bookedAt: string;
    bookedEndAt: string;
    teacher_name: string;
  };
}

async function changeReservedAt(
  consultId: number,
  reserveAt: string,
  reason: string,
) {
  const url = `/reserve/${consultId}`;
  const response = await privateAxiosInstance.patch(url, {
    reserveAt,
    reason,
  });
  return response.data as number;
}

async function readyPayment(
  data: ReservationData,
  merchant_uid: string,
  content: string,
  attatchedFile: File | null,
) {
  const fd = new FormData();
  fd.append('productId', `${data.productId}`);
  fd.append('reserveAt', data.reserveAt);
  if (data.couponId) {
    fd.append('couponId', `${data.couponId}`);
  }
  fd.append('buyerId', `${data.buyerId}`);
  fd.append('total', `${data.total}`);
  fd.append('discount', `${data.discount}`);
  data.field.forEach((v, i) => {
    fd.append(`field[${i}]`, `${v}`);
  });
  data.etcText.forEach((v, i) => {
    fd.append(`etcText[${i}]`, v);
  });
  fd.append('merchant_uid', merchant_uid);
  fd.append('content', content);

  if (attatchedFile) {
    fd.append('file', attatchedFile);
  }
  const response = await privateAxiosInstance.post(`/payment/ready`, fd);
  return response.data;
}

async function readyPresentationPayment(
  data: ReservePresentationData,
  merchant_uid: string,
) {
  const _data = {
    ...data,
    merchant_uid,
  };
  const response = await privateAxiosInstance.post(
    `/payment/presentation-ready`,
    _data,
  );
  return response.data;
}

async function freePresentationPayComplete(
  data: ReservePresentationData,
  merchant_uid: string,
) {
  const _data = {
    ...data,
    merchant_uid,
  };
  const response = await privateAxiosInstance.post(
    `/payment/free-reservation-complete`,
    _data,
  );
  return response.data;
}

async function checkPresentationFull(_id: number) {
  const url = `/payment/check-presentation-full?id=${_id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

async function checkBuyed(presentationId: number, userId: number) {
  const url = `/payment/check-buyed?presentationId=${presentationId}&userId=${userId}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

async function readyLecturePayment(
  data: ReserveLectureData,
  merchant_uid: string,
) {
  const _data = {
    ...data,
    merchant_uid,
  };
  const response = await privateAxiosInstance.post(
    `/payment/lecture-ready`,
    _data,
  );
  return response.data;
}

async function freeLecturePayComplete(
  data: ReserveLectureData,
  merchant_uid: string,
) {
  const _data = {
    ...data,
    merchant_uid,
  };
  const response = await privateAxiosInstance.post(
    `/payment/free-lecture-reservation-complete`,
    _data,
  );
  return response.data;
}

async function checkLectureFull(_id: number) {
  const url = `/payment/check-lecture-full?id=${_id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

async function checkLectureProceed(_id: number) {
  const url = `/payment/check-lecture-proceed?id=${_id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

async function checkLectureBuyed(lectureId: number, userId: number) {
  const url = `/payment/check-lecture-buyed?lectureId=${lectureId}&userId=${userId}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

async function checkLectureBuyedWithItem(itemId: number, userId: number) {
  const url = `/payment/check-lecture-buyed-with-item?itemId=${itemId}&userId=${userId}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

async function checkPeriodOver(itemId: number, userId: number) {
  const url = `/payment/check-lecture-period-over?itemId=${itemId}&userId=${userId}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as boolean;
}

export const reserveApis = {
  payComplete,
  acceptReserve,
  rejectReserve,
  cancelReserve,
  getReserveInfo,
  getCommingSoon,
  getTeacherInfo,
  getReserveTimes,
  freePayComplete,
  changeReservedAt,
  getAllCouponList,
  getSpecificCouponList,
  getRequireReview,
  getChangableDate,
  getNotChangableTime,
  readyPayment,
  readyPresentationPayment,
  freePresentationPayComplete,
  checkPresentationFull,
  checkBuyed,
  readyLecturePayment,
  freeLecturePayComplete,
  checkLectureFull,
  checkLectureProceed,
  checkLectureBuyed,
  checkLectureBuyedWithItem,
  checkPeriodOver,
};
