import { FC } from 'react';
import Select, {
  DropdownIndicatorProps,
  Props,
  components,
} from 'react-select';
import { dropdownIcon } from '../images';

interface CommonDropDownProps extends Props {
  width?: number;
  height?: number;
  hideIcon?: boolean;
  borderColer?: string;
}

const DropdownIndicator: FC<DropdownIndicatorProps<unknown, boolean>> = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={dropdownIcon} className="w-6 h-6 select-none" alt="" />
    </components.DropdownIndicator>
  );
};

const CommonDropDown: FC<CommonDropDownProps> = ({
  styles,
  width,
  height,
  hideIcon = false,
  borderColer = '#C4C8D8',
  ...props
}) => {
  return (
    <Select
      {...props}
      styles={{
        control: (provided) => {
          return {
            ...provided,
            width: width,
            height: height,
            borderColor: borderColer,
            cursor: 'pointer',
          };
        },
        indicatorSeparator: () => ({}),
        valueContainer: (provided) => ({
          ...provided,
          padding: hideIcon ? '' : '12px 2px 12px 12px',
          textAlign: hideIcon ? 'center' : undefined,
          fontSize: '14px',
          fontWeight: '500',
        }),
        dropdownIndicator: () =>
          hideIcon ? {} : { padding: '', paddingRight: 12 },
        placeholder: (provided) => ({
          ...provided,
          color: '#8D8DA5',
          fontWeight: '500',
        }),
        menu: (provided) => ({
          ...provided,
          fontSize: '12px',
        }),
        option: (provided) => ({
          ...provided,
          cursor: 'pointer',
        }),
      }}
      components={{ DropdownIndicator: hideIcon ? null : DropdownIndicator }}
    />
  );
};

CommonDropDown.defaultProps = {} as Partial<CommonDropDownProps>;

export default CommonDropDown;
