import { io, Socket } from 'socket.io-client';
import { Notification } from '../atoms/NotificationState';

export enum NotificationReceiveEventNames {
  SET_INITIAL_NOTIFICATION = 'SET_INITIAL_NOTIFICATION',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

interface NotificationEventHandler {
  (
    eventName: NotificationReceiveEventNames.SET_INITIAL_NOTIFICATION,
    callback: (data: {
      unReadCount: number;
      notifications: Notification[];
    }) => any
  ): () => void;
  (
    eventName: NotificationReceiveEventNames.PUSH_NOTIFICATION,
    callback: (notification: Notification) => any
  ): () => void;
}

export class NotificationSocket {
  private socket: Socket;
  constructor(id: number) {
    this.socket = this.init(id);
  }

  private init(id: number) {
    const url =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:5001/notification-${id}`
        : `https://askhow.co.kr/notification-${id}`;

    const socket = io(url, {
      transports: ['websocket'],
      autoConnect: false,
      reconnection: true,
    });
    return socket;
  }

  public connect() {
    this.socket.connect();
  }

  public disconnect() {
    this.socket.disconnect();
  }
  public addEvent: NotificationEventHandler = (eventName, eventHandler) => {
    this.socket.on(eventName, eventHandler);
    return () => {
      this.socket.off(eventName, eventHandler);
    };
  };

  public requestInitialData() {
    this.socket.emit('HANDSHAKE');
  }
}
