import { atom } from 'recoil';

export type ConsultDoneInfo = {
  _id: number;
  title: string;
  client: {
    name: string;
    profile?: string;
  };
  teacher: {
    name: string;
    profile?: string;
  };
  isTeacher: boolean;
  type: 'chat' | 'video' | 'phone' | 'presentation' | 'lecture';
  item_id?: number;
} | null;

export const requireReviewState = atom<ConsultDoneInfo>({
  key: 'RequireReviewState',
  default: null,
});
