import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { ModalsState } from '../atoms/ModalsState';

const Modals: FC = () => {
  const openedModals = useRecoilValue(ModalsState);

  return (
    <>
      {openedModals.map((modal, index) => {
        const { Component, props } = modal;
        return <Component key={index} {...props} />;
      })}
    </>
  );
};

export default Modals;
