import React, { FC } from 'react';
import ReactModal, { Props } from 'react-modal';
import styled from 'styled-components';

interface CustomModalProps extends Pick<Props, 'onRequestClose'> {
  children?: React.ReactNode;
  className?: string;
}

const CustomModal: FC<CustomModalProps> = ({
  children,
  onRequestClose,
  className,
}) => {
  return (
    <ReactModal
      isOpen
      style={{}}
      onRequestClose={onRequestClose}
      className="bg-transparent h-full"
      overlayClassName="z-[1000] bg-askhow-white fixed inset-0 bg-askhow-black-01 bg-opacity-70"
    >
      <div className="h-[100vh] py-4 relative overflow-overlay p-4 flex items-center">
        <div
          className={`m-auto w-72 md:w-99 rounded-10 shadow-askhow-01 bg-askhow-white overflow-hidden ${
            className || ''
          }`}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

CustomModal.defaultProps = {} as Partial<CustomModalProps>;

export default CustomModal;

export const TopAlignModal: FC<CustomModalProps> = ({
  children,
  className,
  onRequestClose,
}) => {
  return (
    <TopModalContainer
      isOpen
      style={{
        overlay: { zIndex: 1000, backgroundColor: 'rgba(43,43,62,0.7)' },
      }}
      onRequestClose={onRequestClose}
      className={className}
    >
      {children}
    </TopModalContainer>
  );
};

const TopModalContainer = styled(ReactModal)``;
