import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { reserveApis } from '../axios/reserve.apis';
import { useAuth } from './useAuth';
import { useConsultDoneInfo } from './useConsultDoneInfo';

export function useFetchConsultDoneInfo() {
  const { isLogin } = useAuth();
  const location = useLocation();
  const [, setData] = useConsultDoneInfo();

  useEffect(() => {
    if (isLogin === false) {
      setData(null);
      return;
    }
    let mounted = true;

    reserveApis
      .getRequireReview()
      .then((res) => {
        if (mounted === true) {
          setData(res.result);
        }
      })
      .catch(() => {
        setData(null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, location.pathname]);
}
