import { privateAxiosInstance, publicAxiosInstance } from '.';
import { PaginateData } from '../types/paginate-data.interface';
import { PresentationListItemInterface } from '../types/presentation-list-item.interface';

export type PresentationListItem = {
  _id: number;
  name: string;
  description: string;
  price: number;
};

export type MyPagePresentationList =
  | {
      isAgree: false;
    }
  | {
      isAgree: true;
      items: PresentationListItem[];
    };

async function getPresentationProductList() {
  const url = '/mypage/presentation';
  const response = await privateAxiosInstance.get(url);
  return response.data as MyPagePresentationList;
}

export type CreatePresentationDto = {
  name: string;
  field: number[];
  etcText: string[];
  startAt: Date;
  endAt: Date;
  price: number;
  description: string;
  maxBuyedCount: number;
};

async function createPresentation(data: CreatePresentationDto) {
  const body = {
    name: data.name,
    field: data.field,
    etcText: data.etcText,
    startAt: data.startAt.getTime(),
    endAt: data.endAt.getTime(),
    price: data.price,
    description: data.description,
    maxBuyedCount: data.maxBuyedCount,
  };
  const url = '/mypage/presentation';
  await privateAxiosInstance.post(url, body);
}

async function deletePresentation(id: number) {
  const url = `/mypage/presentation/${id}`;
  await privateAxiosInstance.delete(url);
}

export type PresentationDetailItem = {
  _id: number;
  name: string;
  startAt: string;
  endAt: string;
  participant: number;
  teacher: string;
  price: number;
};
async function getOneForTeacher(id: number) {
  const url = `/mypage/presentation/${id}`;
  const response = await privateAxiosInstance.get(url);
  return response.data as PresentationDetailItem;
}

export type ParticipantItem = {
  index: number;
  name: string;
  status: string;
};

async function getParticipantList(id: number, page: number) {
  const url = `/mypage/presentation/${id}/participant?page=${page}`;

  const response = await privateAxiosInstance.get(url);
  return response.data as PaginateData<ParticipantItem>;
}

async function handleDownlaodParticipant(id: number) {
  privateAxiosInstance({
    url: `/mypage/presentation/${id}/participant/excel`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    let fileName = '설명회신청자목록.xlsx';

    const navigator = (window as any).navigator;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        fileName,
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
}

async function getHomeList() {
  const response = await publicAxiosInstance.get('/presentation/home');
  return response.data as PresentationListItemInterface[];
}
export type PresentationDetailForUser = {
  _id: number;
  name: string;
  period: string;
  createdAt: string;
  teacherName: string;
  clientName: string;
  price: number;
  status: string;
  hasReview: boolean;
  review: {
    _id: number;
    star: number;
    content: string;
  } | null;
};

async function getOneForUser(id: number) {
  const url = `/mypage/presentation-user/${id}`;
  const response = await privateAxiosInstance.get(url);

  return response.data as PresentationDetailForUser;
}

async function cancelTicket(id: number, content: string) {
  const url = `/presentation/ticket/${id}`;
  await privateAxiosInstance.delete(url, {
    data: { content },
  });
}

async function joinTicket(ticket_id: number) {
  const url = `/presentation/ticket/${ticket_id}/join`;
  const response = await privateAxiosInstance.get(url);
  return response.data as { url: string };
}

async function createReview(ticket_id: number, star: number, text: string) {
  const url = `/presentation/ticket/${ticket_id}/review`;
  await privateAxiosInstance.post(url, {
    star,
    text,
  });
}

export const presentationApis = {
  getPresentationProductList,
  createPresentation,
  deletePresentation,
  getOneForTeacher,
  getOneForUser,
  getParticipantList,
  handleDownlaodParticipant,
  getHomeList,
  cancelTicket,
  joinTicket,
  createReview,
};
