import { FC, ReactNode, useCallback } from 'react';
import { useModals } from '../hooks/useModal';
import { closeIconBlack03 } from '../images';
import CustomModal from './components/CustomModal';

interface TermModalProps {
  content?: ReactNode;
  label?: string;
}

const TermModal: FC<TermModalProps> = ({ content, label }) => {
  const { closeModal } = useModals();
  const close = useCallback(() => {
    closeModal(TermModal);
  }, [closeModal]);
  return (
    <CustomModal>
      <div className="px-6 py-5 lg:px-12 lg:py-10 relative">
        <button
          className="absolute right-5 top-5 w-4 h-4"
          type="button"
          onClick={close}
        >
          <img src={closeIconBlack03} alt="닫기" />
        </button>
        <h1 className="text-center text-h3 font-bold mb-8">{label}</h1>
        <div className="max-h-[50vh] overflow-y-scroll">{content}</div>
      </div>
    </CustomModal>
  );
};

TermModal.defaultProps = {} as Partial<TermModalProps>;

export default TermModal;
