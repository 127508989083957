import { useEffect, useRef } from 'react';
import {
  NotificationReceiveEventNames,
  NotificationSocket,
} from '../sockets/NotificationSocket';
import { useAuth } from './useAuth';
import { useNotifications } from './useNotifications';

export function useNotificationSocket() {
  const { isLogin, authState } = useAuth();
  const [, setNotifications] = useNotifications();
  const socket = useRef<NotificationSocket | null>(null);

  useEffect(() => {
    if (isLogin === true) {
      if (socket.current !== null) {
        socket.current.disconnect();
        socket.current = null;
      }
      const newSocket = new NotificationSocket(authState._id);
      socket.current = newSocket;
      const unsubscribes = [
        newSocket.addEvent(
          NotificationReceiveEventNames.PUSH_NOTIFICATION,
          (notification) => {
            setNotifications((prev_notifications) => {
              const next_total = prev_notifications.total + 1;
              const next_notifications = [
                notification,
                ...prev_notifications.notifications,
              ];
              return {
                total: next_total,
                notifications: next_notifications.splice(0, 10),
              };
            });
          }
        ),
        newSocket.addEvent(
          NotificationReceiveEventNames.SET_INITIAL_NOTIFICATION,
          ({ unReadCount, notifications }) => {
            setNotifications({
              total: unReadCount,
              notifications,
            });
          }
        ),
      ];
      newSocket.connect();
      newSocket.requestInitialData();
      return () => {
        unsubscribes.forEach((v) => v());
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);
}
