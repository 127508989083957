import { FC } from 'react';
import { atom } from 'recoil';

export interface Modals<P extends {}> {
  Component: FC<P>;
  props: P;
}

export const ModalsState = atom({
  key: 'ModalsState',
  default: [] as Modals<any>[],
});
