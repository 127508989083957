// 이곳은 공통에 존재하는 Alert의 문구만 저장합니다.

const UPLOAD_ERROR_TEXT = '오류가 발생했습니다.\n다시 시도해주세요.';
const LARGE_TOO_FILE_ERROR_TEXT =
  '해당 파일은 용량 초과로 업로드가 불가합니다.\n다시 시도해주세요.';
const EDIT_DONE_TEXT = '수정이 완료되었습니다.';
const CANCEL_OR_DELETE_TEXT = '해당 항목을 삭제(취소) 하시겠습니까?';
const CANCEL_OR_DELETE_TEXT2 = '해당 항목을 삭제 하시겠습니까?';
const CANCEL_OR_DELETE_HEADER_TEXT = '삭제 후에는 되돌릴 수 없습니다.';
const SAVE_DONE_TEXT = '저장이 완료되었습니다.';
const UPLOAD_DONE_TEXT = '등록이 완료되었습니다.';
const LOGIN_REQUIRED_TEXT = '로그인 후 이용 가능합니다.';
const REGISTER_REQUIRED_TEXT = '회원가입 후 이용하실 수 있습니다.';
const TEACHER_PROFILE_SAVE_HEADER_TEXT = '수정된 내용을 저장하시겠습니까?';
const TEACHER_PROFILE_SAVE_BODY_TEXT =
  '저장 후 관리자 검토 후 프로필이 노출됩니다.';

export const ModalText = {
  UPLOAD_ERROR_TEXT,
  LARGE_TOO_FILE_ERROR_TEXT,
  EDIT_DONE_TEXT,
  CANCEL_OR_DELETE_TEXT,
  CANCEL_OR_DELETE_TEXT2,
  CANCEL_OR_DELETE_HEADER_TEXT,
  SAVE_DONE_TEXT,
  UPLOAD_DONE_TEXT,
  LOGIN_REQUIRED_TEXT,
  REGISTER_REQUIRED_TEXT,
  TEACHER_PROFILE_SAVE_HEADER_TEXT,
  TEACHER_PROFILE_SAVE_BODY_TEXT,
};
